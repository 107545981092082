import React from 'react';
import './LPSlots.css';

const LPSlots = () => {
    return(
        <div className='lp-slots'>
            <table border='1' cellSpacing='2' cellPadding='2' className='lp-slots-table'>
                <tr>
                    <th colSpan='2' className='lp-slots-table-heading'>Regular classes Monday - Friday</th>
                </tr>
                <tr>
                    <th>Morning</th>
                    <th>Evening</th>
                </tr>
                <tr>
                    <td><p className='lp-slot-timing'>06:00 - 06:55 AM</p></td>
                    <td><p className='lp-slot-timing'>05:00 - 05:55 PM</p></td>
                </tr>
                <tr>
                    <td><p className='lp-slot-timing'>07:00 - 07:55 AM</p></td>
                    <td><p className='lp-slot-timing'>06:00 - 06:55 PM</p></td>
                </tr>
                <tr>
                    <td><p className='lp-slot-timing'>08:00 - 08:55 AM</p></td>
                    <td><p className='lp-slot-timing'>07:00 - 07:55 PM</p></td>
                </tr>
                <tr>
                    <th colSpan='2' className='lp-last-row'>Dedicated batches for Women, Diabetes, Kneepain, Backpain, Asthma</th>
                </tr>
            </table>
        </div>
    )
}

export default LPSlots;