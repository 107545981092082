import React,{useState} from 'react';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import homeYogaImg from "../../assets/YogantaHome-pp.webp";
import yogantaHome1 from "../../assets/yogantaHome1.webp";
import yogantaHomeCollage1 from "../../assets/yogantaHome-collage1-min.png";
import yogantaHomeCollage2 from "../../assets/yogantaHome-collage2-min.png";
import hyderabad from "../../assets/Hyderabad-y.png";
import delhi from "../../assets/Delhi-y.png";
import mumbai from "../../assets/Mumbai-y.png";
import kolkata from "../../assets/Kolkata-y.png";
import bengaluru from "../../assets/Bengaluru-y.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PunchLine from '../commonComps/PunchLine';
import './yogantaHome.css'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const YogantaHome=()=>{
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };

  const [readMore,setReadMore]=useState(false);

  const extraContent=<div className="life-yoganta">
      <img src={yogantaHomeCollage1 } alt="Yoga at home collage 1 YogantaHome" />
      <img src={yogantaHomeCollage2 } alt="Yoga at home collage 2 YogantaHome" />
  </div>
  const linkName=readMore?'See less ↑':'See more ↓' 

  return(
    <div className='yoganta-home-main'>
     {/* Metadata */}
     <Helmet>
        <title>Yoga at Home | Home Yoga classes in Hyderabad - YogantaHome</title>
        <meta name="description" content="YogantaHome - Start your yoga journey with us learn from the best yoga teacher at the comfort of your home, schedule a Demo now," />
        <link rel="canonical" href="https://www.yoganta.in/yogantaHome"/>
		    <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
		    <meta name="keywords" content=" yogantaHome, home yoga classes in Hyderabad, Home yoga teacher near me, home yoga classes Hyderabad, Delhi, Mumbai, Kolkata, Bengaluru, therapeutic yoga in Hyderabad, yoga at home, home yoga instructor in Hyderabad, benefits of yoga at home" />
      
      </Helmet>
    {/* Metadata */}
      <section className='yoganta-home-first-sec'>
          <div className='yoganta-home-first-sec-text'>
              <h1>Yoganta<span>Home</span></h1>
              {/* <h3>Your Place, Your Practice, Your Needs, Your Choice</h3> */}
              <p><strong>Home Yoga Classes</strong> learn from the best yoga teacher at the comfort of your home.</p>
              <h3>Schedule a Demo Today!</h3>
              <h3><a href="tel:+919985955527">+91 99859 55527</a></h3>
          </div>
          <div className='yoganta-home-first-sec-img'>
              <img src={homeYogaImg} alt="Yoganta Home hero img" />
          </div>
      </section>

      <section className='yoganta-home-benifits'>
        <div className='yoganta-home-benifits-heading'>
          <h2>Benefits of Yoganta Home</h2>
        </div>
        <div className='yoganta-home-benifits-benifit'>
          <div className='yoganta-home-benifits-benifit-card'>
            <div><CheckCircleIcon color="" /></div>
            <div classname='yoganta-home-benifits-benifit-card-text'><p>Well Trained Experienced Teachers</p></div>
          </div>
          <div className='yoganta-home-benifits-benifit-card'>
            <div><CheckCircleIcon /></div>
            <div classname='yoganta-home-benifits-benifit-card-text'><p>Pause and Resume Subscription</p></div>
          </div>
          <div className='yoganta-home-benifits-benifit-card'>
            <div><CheckCircleIcon /></div>
            <div classname='yoganta-home-benifits-benifit-card-text'><p>Teacher Replacement Option</p></div>
          </div>
          <div className='yoganta-home-benifits-benifit-card'>
            <div><CheckCircleIcon /></div>
            <div classname='yoganta-home-benifits-benifit-card-text'><p>Practice Yoga with Family</p></div>
          </div>
          <div className='yoganta-home-benifits-benifit-card'>
            <div><CheckCircleIcon /></div>
            <div classname='yoganta-home-benifits-benifit-card-text'><p>Diet and Nutrition Advice</p></div>
          </div>
          <div className='yoganta-home-benifits-benifit-card'>
            <div><CheckCircleIcon /></div>
            <div classname='yoganta-home-benifits-benifit-card-text'><p>Access of Yoganta Community</p></div>
          </div>
        </div>
        
      </section>

      <section className='yoganta-home-avialable-in'>
        <div className='yoganta-home-avialable-in-heading'>
          <h2>Available in</h2>
        </div>
        <div className='yoganta-home-avialable-in-icons'>
          <div>
            <div className='yoganta-home-avialable-in-icon'><img src={hyderabad} alt='hyderabad-city-icon'></img></div>
            
            <div className='yoganta-home-avialable-in-icon-heading'>Hyderabad</div>
          </div>
          <div>
            <div className='yoganta-home-avialable-in-icon'><img src={delhi} alt='delhi-city-icon'></img></div>
            <div className='yoganta-home-avialable-in-icon-heading'>Delhi</div>
          </div>
          <div>
            <div className='yoganta-home-avialable-in-icon'><img src={mumbai} alt='mumbai-city-icon'></img></div>
            <div className='yoganta-home-avialable-in-icon-heading'>Mumbai</div>
          </div>
          <div>
            <div className='yoganta-home-avialable-in-icon'><img src={kolkata} alt='kolkata-city-icon'></img></div>
            <div className='yoganta-home-avialable-in-icon-heading'>Kolkata</div>
          </div>
          <div>
            <div className='yoganta-home-avialable-in-icon'><img src={bengaluru} alt='bengaluru-city-icon'></img></div>
            <div className='yoganta-home-avialable-in-icon-heading'>Bengaluru</div>
          </div>
        </div>
        </section>
        <section  className="carousel">
        <Carousel responsive={responsive} >
          <div className="card">
            <div className='yoganta-home-avialable-in-icon'><img src={hyderabad} alt='hyderabad-city-icon'></img></div>
            <div className='yoganta-home-avialable-in-icon-heading'>Hyderabad</div>
          </div>
          <div  className="card">
            <div className='yoganta-home-avialable-in-icon'><img src={delhi} alt='delhi-city-icon'></img></div>
            <div className='yoganta-home-avialable-in-icon-heading'>Delhi</div>
          </div>
          <div  className="card">
            <div className='yoganta-home-avialable-in-icon'><img src={mumbai} alt='mumbai-city-icon'></img></div>
            <div className='yoganta-home-avialable-in-icon-heading'>Mumbai</div>
          </div>
          <div  className="card">
            <div className='yoganta-home-avialable-in-icon'><img src={kolkata} alt='kolkata-city-icon'></img></div>
            <div className='yoganta-home-avialable-in-icon-heading'>Kolkata</div>
          </div>
          <div  className="card">
            <div className='yoganta-home-avialable-in-icon'><img src={bengaluru} alt='bengaluru-city-icon'></img></div>
            <div className='yoganta-home-avialable-in-icon-heading'>Bengaluru</div>
          </div>
          </Carousel>
          </section>

      <section className="life-yoganta">
        <h2>Yoganta Home Gallery</h2>
        <img src={yogantaHome1} alt="Yoganta Home collage for yoga at home " />

        {readMore && extraContent}
        <button className="life-yoganta-btn " type="button" onClick={()=>{setReadMore(!readMore)}} data-toggle="collapse" data-target="#moreimages" aria-expanded="false" aria-controls="moreimages">
          <h3>{linkName}</h3>
        </button>
      </section>

      <section className='yoganta-home-get-started'>
        <div className='yoganta-home-get-started-main'>
          <p>COMMIT TO YOURSELF...! Contact us at<br /><a href="mailto:hello@yoganta.in">hello@yoganta.in</a> or <a href="tel:+919985955527">+91 99859 55527</a></p>
        </div>
      </section>

      <PunchLine message="Nourish your body, balance your mind"/>
    </div>
  );
}

export default YogantaHome;