import React from 'react';
import './LPTrainers.css';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RaushanCrop from "../../../../assets/Raushan-crop.png";
import Nishi from "../../../../assets/Nishi-Nutritionist.webp";
import Kaavya from "../../../../assets/Kaavya.webp";
import Meena from "../../../../assets/Meena.webp";
import Anjali from "../../../../assets/Anjali-singh.jpeg";
// import Yamuna from "../../../../assets/Yamuna-crop.webp";
import Tanisha from "../../../../assets/tanisha.jpeg";
import Amrutha from "../../../../assets/amrutha.jpeg";



const LPTrainers = () => {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 468 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 468, min: 0 },
          items: 1
        }
      };

    return(
        <section className='lptrainer'>
            <div className='lptrainer-main'>
                <h2>Meet the Trainers</h2>
            </div>
            <Carousel responsive={responsive}>
            <div className='lptrainer-main-text'>
                <img src={Kaavya} alt='Yoganta Yoga Trainer Kaavya' />
                <div className='lptrainer-main-text-name'>
                    <p>Kaavya Yedla</p>
                    <p>Yoga Instructor</p>
                </div>
            </div>
            <div className='lptrainer-main-text'>
                <img src={Anjali} alt='Yoganta Yoga Trainer Anjali Singh Rathore' />
                <div className='lptrainer-main-text-name'>
                    <p>Anjali Singh Rathore</p>
                    <p>Yoga Instructor</p>
                </div>
            </div>
            <div className='lptrainer-main-text'>
                <img src={RaushanCrop} alt='Raushan Kumar' />
                <div className='lptrainer-main-text-name'>
                    <p>Raushan Kumar</p>
                    <p>Yoga Instructor</p>
                </div>
            </div>
            <div className='lptrainer-main-text'>
                <img src={Nishi} alt='Yoganta Nutritionist Nishi Gupta' />
                <div className='lptrainer-main-text-name'>
                    <p>Nishi Gupta</p>
                    <p>Nutritionist</p>
                </div>
            </div>
          
            <div className='lptrainer-main-text'>
                <img src={Meena} alt='Yoganta Yoga Trainer Meena' />
                <div className='lptrainer-main-text-name'>
                    <p>Meena Jethi</p>
                    <p>Sr. Yoga Instructor</p>
                    <p>Reiki Master</p>

                </div>
            </div>
            <div className='lptrainer-main-text'>
                <img src={Tanisha} alt='Yoganta Yoga Trainer Tanisha Agrawal' />
                <div className='lptrainer-main-text-name'>
                    <p>Tanisha Agrawal</p>
                    <p>Yoga Instructor</p>
                </div>
            </div>
            <div className='lptrainer-main-text'>
                <img src={Amrutha} alt='Yoganta Yoga Trainer Amrutha Sharma' />
                <div className='lptrainer-main-text-name'>
                    <p>Amrutha Sharma</p>
                    <p>Yoga Instructor</p>
                </div>
            </div>
            </Carousel>
        </section>
    )
}

export default LPTrainers;