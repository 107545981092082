import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import YogantaLogoImg from '../../assets/logo-white-154x64.svg'

function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
        <NavLink to="/" className="nav-logo">
             <img src={YogantaLogoImg} alt="yoganta logo"/>
        </NavLink>


          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                to="YogantaLive"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                YogantaLive
              </NavLink>
            </li>


            <li className="nav-item">
              <NavLink
                to="YogantaHome"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                YogantaHome
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="YogantaCure"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                YogantaCure
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/pricing"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Pricing
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/shop"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Shop
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;