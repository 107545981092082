import React from 'react';
import './Footer.css';
import Logo from '../../assets/logo-white-154x64.svg';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { NavLink, Link as LinkTo  } from 'react-router-dom';

function Footer (){

    const today = new Date();

    return (
    
        <footer>
            <div className = "main-footer">
                <div className="footer-main-content container">
                    <div className="about-yoganta">
                        <div className="logo">
                        <LinkTo to="/"> <img src={Logo} alt="yoganta logo"/></LinkTo>
                            {/* <img src={Logo} alt="yoganta logo"/> */}
                            <p>Yoganta offers online and offline health and wellness services in fitness, yoga, nutrition, chronic pain management, and mental wellness</p>
                        </div>
                    </div>

                    {/* <div className="get-app" id="hide">
                        <NavLink to="/" className="google-play-badge">
                        </NavLink>

                        <NavLink to="/" className="app-store-badge">
                        </NavLink>
                    </div> */}

                    <div className="get-app" id="hide">
                        <p>Talk to Us</p>
                        <p>Phone : <a href="tel:+919985955527">+91 99859 55527</a></p>
                        <p>Email : <a href="mailto:hello@yoganta.in">hello@yoganta.in</a></p>
                    </div>

                    {/* <div className="get-app">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15224.333876618028!2d78.3655302!3d17.4557186!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb81b95c934d0a5af!2sYoganta!5e0!3m2!1sen!2sin!4v1657101129942!5m2!1sen!2sin" width="390" height="150" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div> */}

    
                    <div className="footer-link">
                        <div className="link-col-1">
                            <a rel= "noreferrer noopener" href="https://www.yoganta.in/blog" target="_blank">Blog</a>
                            {/* <NavLink to="/blog" target="_blank">Blog</NavLink> */}
                            <NavLink to="/about">About Us</NavLink>
                            <NavLink to="/contact">Contact Us</NavLink>
                        </div>
                        <div className="link-col-2">
                            {/* <NavLink to="shop">Shop</NavLink> */}
                            <NavLink to="/pricing">Pricing</NavLink>
                            <NavLink to="/careers">Careers</NavLink>
                            <NavLink to="/corporate-wellness">Corporate Wellness</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        

        {/* <!-- <div className="footer-separation-row">
        </div> --> */}
        <div className="footer-bottom">
            <div className="footer-content-bottom container">
                <div className="list-a">
                    <NavLink to="/terms-and-conditions">Terms of use</NavLink>
                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                    <NavLink to="/faq">FAQ</NavLink>
                </div>
                <div className="list-b">
                    <a rel= "noreferrer noopener" href="https://www.facebook.com/TheYoganta/" target="_blank"><FacebookIcon /></a>
                    <a rel= "noreferrer noopener" href="https://www.instagram.com/theyoganta/" target="_blank"><InstagramIcon /></a>
                    <a rel= "noreferrer noopener"  href="https://twitter.com/TheYoganta" target="_blank"><TwitterIcon /></a>
                    <a rel= "noreferrer noopener" href="https://www.linkedin.com/company/theyoganta" target="_blank"><LinkedInIcon /></a>
                    <a rel= "noreferrer noopener"  href="https://in.pinterest.com/theyoganta/" target="_blank"><PinterestIcon /></a>
                    <a rel= "noreferrer noopener"  href="https://www.youtube.com/channel/UCdgJhbumN3X6hRBmndCSyaQ" target="_blank"><YouTubeIcon /></a>
                </div>
                <div className="list-c">
                    Copyright &copy; {today.getFullYear()}<NavLink to="/"><strong>&nbsp;Yoganta</strong></NavLink>. All rights reserved.
                </div>

            </div>
        </div>
    </footer>
    );
}

export default Footer;