import React from 'react';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import PunchLine from '../commonComps/PunchLine';
import './Faq.css';
import faqImg from '../../assets/faq.jpg';
import Accordian from "./Accordian";
function Faq ({punchmsg}) {
    return (
      <div>
         {/* Metadata */}
         <Helmet>
            <title> Frequently Asked Questions - Yoganta</title>
            <meta name="description" content="Read Yoganta frequently asked questions for your all your general queries" />
            <link rel="canonical" href="https://www.yoganta.in/faq"/>
            <meta name="keywords" content="yoganta faq, yoganta frequently asked questions, yoganta helpline" />
          
          </Helmet>
        {/* Metadata */}
        <img className="faqBG" src={faqImg} alt="FAQ banner" />
        <div className="accordian">
          <Accordian/>
        </div>
        <PunchLine message="Life is too short to read the FAQ. Call directly." />
      </div>
    );
  }
  
  export default Faq;