import React from 'react';
import './LPContact.css';


const LPContact = () => {
    return(
        <div className='group-yoga-lp-contact'>
            <div className='group-yoga-lp-contact-main'>
                <p>Still, have any questions? Contact us<br /><a href="mailto:hello@yoganta.in">hello@yoganta.in</a> or <a href="tel:+919985955527">+91 99859 55527</a></p>
            </div>
      </div>
    )
}

export default LPContact;