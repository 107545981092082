import React from 'react';
import { NavLink } from 'react-router-dom';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import './YogaChallenge.css';
import YogaChallengeImg from "../../assets/yogaChallengeBanner.jpg";
import YouTubeIcon from '@mui/icons-material/YouTube';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import PunchLine from '../commonComps/PunchLine';

function YogaChallenge() {
  return (
    <div className="contact">
    {/* Metadata */}
     <Helmet>
        <title>Yoganta Online Yoga Challenge</title>
        <meta name="description" content="Welcome to the most exciting Monthly Yoga Challenge. Join Suryanamaskar Challenge and Let's start your fitness journey with Yoganta" />
        <link rel="canonical" href="https://www.yoganta.in/yoganta-online-yoga-challenge"/>
		    <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
		    <meta name="keywords" content="yoganta suryanamaskar challenge,online yoga challenge, yoga challenge, yoga challenge online, virtual yoga, free yoga classes online, yoganta Hyderabad" />
      
      </Helmet>
    {/* Metadata */}
      <div className="contact-main">
      <div className="contact-main-main">
        <div className="contact-main-texts">
          <h1>Yoganta Online Yoga Challenge</h1>
          <p className="contact-main-texts-paraOne">Welcome to the most exciting Monthly Yoga Challenge</p><hr /><br />
          <button type='button' className='BigButtonYC'> <NavLink to="/suryanamaskar-challenge-january-2022">Yoga Challenge January 2022</NavLink> </button>
          <br/><button type='button' className='BigButtonYC'> <NavLink to="/suryanamaskar-challenge-november-2021">Yoga Challenge November 2021</NavLink> </button>  
          <br/><button type='button' className='BigButtonYC'> <NavLink to="/suryanamaskar-challenge-oct21">Yoga Challenge October 2021</NavLink> </button> 
          <br/>        
        <br/><br/><hr/>
          <p>Connect With Us</p>
          <div className="contact-main-texts-links">
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://www.facebook.com/TheYoganta/" target="_blank"><FacebookIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://www.instagram.com/theyoganta/" target="_blank"><InstagramIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://twitter.com/TheYoganta" target="_blank"><TwitterIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://www.linkedin.com/company/theyoganta" target="_blank"><LinkedInIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://in.pinterest.com/theyoganta/" target="_blank"><PinterestIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://www.youtube.com/Yoganta" target="_blank"><YouTubeIcon /></a>
          </div>
        </div>
        <div className="contact-main-img">
              <img src={YogaChallengeImg} alt="Yoga Challenge banner" />
        </div>
      </div>
      </div>
      <PunchLine message="Yoga Challenge Yourself"/>
    </div>
  );
}

export default YogaChallenge;