import React from 'react';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';

function SuryanamaskarJan2022() {
  return (
    <div className="challenge">
    {/* Metadata */}
     <Helmet>
        <title>Yoganta Suryanamaskar Challenge January 2022</title>
        <meta name="description" content="Yoganta Suryanamaskar Challenge January 2022" />
        <link rel="canonical" href="https://www.yoganta.in/suryanamaskar-challenge-january-2022"/>
		<meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
		<meta name="keywords" content="Yoganta Suryanamaskar Challenge January 2022, yoganta, yoga challenge, free yoga classes," />
      
      </Helmet>
    {/* Metadata */}
        <div className="contact-main">
            <iframe title="January 2022" width =	"100%" height = "800px" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRsIELWi2wEeT38gydr_ngOdq_Rm8wrdU4-tLtHagh3D00yN5Ia4SQ3oZjBUpeimSWRhml6jNpQhqjO/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
       </div>
    </div>
  );
}

export default SuryanamaskarJan2022;