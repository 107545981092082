import React from 'react';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import './TermsOfUse.css';
import PunchLine from '../commonComps/PunchLine';

function TermsOfUse() {
    return (
        <div>
            {/* Metadata */}
                <Helmet>
                    <title>Terms and conditions - Yoganta</title>
                    <meta name="description" content="Yoganta Terms & Conditions, Read our full terms & conditions here" />
                    <link rel="canonical" href="https://www.yoganta.in/terms-and-conditions"/>
                </Helmet>
            {/* Metadata */}
            <section className="terms-of-use-top">
                <h1 className="terms-of-use-top-heading">Terms of Use</h1>
            </section>

            <section className='terms-of-use-policies'>
            <div className='terms-of-use-policies-div'>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Yoganta Terms of Use</h3>
                    <p className='terms-of-use-policies-texts-para'>Last updated July 07, 2021</p>
                    <p className='terms-of-use-policies-texts-para'>Welcome to www.theyoganta.com/www.yoganta.in. This website is owned and operated by Yoganta Technologies Private Limited. The terms "Yoganta", "Company", "we", "us" and "our" refer to the Yoganta Technologies Private Limited.The term "Yoganta Technologies Private Limited Platform" refers to www.theyoganta.com/www.yoganta.in or the Yoganta Technologies Private Limited mobile application or any other digital medium and other offline sources.By visiting our website and accessing the information, resources, services, products, and tools we provide, you understand and agree to accept and adhere to the following terms and conditions as stated in this policy (hereinafter referred to as the ‘Agreement’), along with the terms and conditions as stated in our Privacy Policy (please refer to the Privacy Policy section below for more information). We reserve the right to change this Agreement from time to time with/without notice. You acknowledge and agree that it is your responsibility to review this Agreement periodically to familiarize yourself with any modifications. Your continued use of this site after such modifications will constitute acknowledgment and agreement of the modified terms and conditions.</p>
                    <br />
                    <p>PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
                    This Agreement governs your use of this website www.theyoganta.com (hereinafter referred to as the "Website"), Yoganta Technologies Private Limited’s offer of services for purchase on this Website, or your purchase of Services available on this Website. This Agreement includes and incorporates by this reference, the policies and guidelines referred below. Yoganta reserves the right to change or revise the terms and conditions of
                    this Agreement at any time by posting any changes or a revised Agreement on this Website. Yoganta Technologies Private Limited will/will not alert you that changes or revisions have been made by indicating on the top of this Agreement the date it was last revised. The changed or revised Agreement will be effective immediately after it is posted on this Website. Your use of the Website following the posting of any such changes or of a revised Agreement will constitute your acceptance of any such changes or revisions. Yoganta Technologies Private Limited encourages you to review this Agreement whenever you visit the Website to make sure that you understand the terms and conditions governing the use of the Website. This Agreement does not alter in any way the terms or conditions of any other written agreement you may have with Yoganta Technologies Private Limited for other products or services. If you do not agree to this Agreement (including any referenced policies or guidelines), please immediately terminate your use of the Website.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Responsible Use And Conduct</h3>
                    <p className='terms-of-use-policies-texts-para'>In order to access our Resources/ Services, you may be required to provide certain information about yourself (such as identification, email, phone number, contact details, etc.) as part of the registration process, or as part of your ability to use the Resources. You agree that any information you provide will always be accurate, correct, and up to date.You are responsible for maintaining the confidentiality of any login information associated with any account you use to access our Resources/ Services. Accordingly, you are responsible for all activities that occur under your accounts. Accessing (or attempting to access) any of our Resources/ Services by any means other than through the means we provide, is strictly prohibited. You specifically agree not to access (or attempt to access) any of our Resources/ Services through any automated, unethical or unconventional means. Engaging in any activity that disrupts or interferes with our Resources/ Services, including the servers and/or networks to which our Resources / Services are located or connected, is strictly prohibited. Attempting to copy, duplicate, reproduce, sell, trade, or resell our Resources / Services is strictly prohibited. You are solely responsible for any consequences, losses, or damages that we may directly or indirectly incur or suffer due to any unauthorized activities conducted by you, as explained above, and may incur criminal or civil liability.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Privacy:</h3>
                    <p className='terms-of-use-policies-texts-para'>This Website offers for sale certain Services (hereinafter referred to as ‘ Services’). By placing an order for Services through this Website, you agree to the terms set forth in this Agreement.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Services - Terms of Offer:</h3>
                    <p className='terms-of-use-policies-texts-para'> </p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Customer Solicitation:</h3>
                    <p className='terms-of-use-policies-texts-para'>Unless you notify our third party call center representatives or direct Yoganta sales representatives, while they are calling you, of your desire to opt-out from further direct company communications and solicitations, you are agreeing to continue to receive further emails and call solicitations from Yoganta and its designated in house or third party call team(s).</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Opt-Out Procedure:</h3>
                    <p className='terms-of-use-policies-texts-para'>We provide 3 easy ways to opt-out from future solicitations -

You may use the opt-out link found in any email solicitation

that you may receive.

You may also choose to opt-out, via sending your email address to [opt-out email].

You may send a written remove request to Yoganta Technologies Private Limited sales representatives, while they are calling you, of your desire to opt-out from further direct company communications and solicitations, you are agreeing to continue to receive further emails and call solicitations from Yoganta Technologies Private Limited.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Proprietary Rights:</h3>
                    <p className='terms-of-use-policies-texts-para'>Yoganta Technologies Private Limited has proprietary rights and trade secrets in the Services. You may not copy, reproduce, resell or redistribute any Product manufactured and/or distributed by Yoganta Technologies Private Limited. Yoganta also has rights to all trademarks and trade dress and specific layouts of this webpage, including calls to action, text placement, images, and other information.

Taxes: If you purchase any Services, you will be responsible for paying the applicable taxes. (GST).</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Content, Intellectual Property, Third-Party Links:</h3>
                    <p className='terms-of-use-policies-texts-para'>In addition to making Services available, this Website also offers information and marketing materials. This Website also offers information, both directly and through indirect links to third-party websites, about (kind of information). Yoganta does not always create the information offered on this Website; instead, the information is often gathered from other sources. To the extent that Yoganta does create the content on this Website, such content is protected by intellectual property laws of India, foreign nations, and international bodies. Unauthorized use of the material may violate copyright, trademark, and/or other laws. You acknowledge that your use of the content on this Website is for personal, non-commercial use. Any links to third-party websites are provided solely as a convenience to you. Yoganta does not endorse the contents on any such third-party websites. Yoganta is not responsible for the content of or any damage that may result from your access to or reliance on these third-party websites. If you link to third-party websites, you do so at your own risk.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Use of Website:</h3>
                    <p className='terms-of-use-policies-texts-para'>Yoganta Technologies Private Limited is not responsible for any damages resulting from the use of this website by anyone. You will not use the Website for illegal purposes. You will - abide by all applicable local, state, national, and international laws and regulations in your use of the Website (including laws regarding intellectual property), not interfere with or disrupt the use and enjoyment of the Website by other users, not resell material on the Website, not engage, directly or indirectly, in transmission of "spam", chain letters, junk mail or any other type of unsolicited communication, and not defame, harass, abuse, or disrupt other users of the Website.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>License:</h3>
                    <p className='terms-of-use-policies-texts-para'>By using this Website, you are granted a limited, non-exclusive, non-transferable right to use the content and materials on the Website in connection with your normal, non-commercial use of the Website. You may not copy, reproduce, transmit, distribute, or create derivative works of such content or information without express written authorization from Yoganta or the applicable third party (if third party content is at issue).</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Blogs:</h3>
                    <p className='terms-of-use-policies-texts-para'>We may provide various open communication tools on our website, such as blog comments, blog posts, public chat, forums, message boards, newsgroups, product ratings and reviews, various social media services, etc. You understand that generally we do not pre-screen or monitor the content posted by users of

these various communication tools, which means that if you choose to use these tools to submit any type of content to our website, then it is your personal responsibility to use these tools in a responsible and ethical manner. By posting information or otherwise using any open communication tools as mentioned,

you agree that you will not upload, post, share, or otherwise distribute any content that: is illegal, threatening, defamatory, abusive, harassing, degrading,

intimidating, fraudulent, deceptive, invasive, racist, or contains any type of suggestive, inappropriate, or explicit language; infringes on any trademark, patent, trade secret, copyright, or other proprietary right of any party; contains any type of unauthorized or unsolicited advertising; impersonates any person or entity, including any www.theyoganta.com/www.yoganta.in Yoganta Technologies Private Limited.

employees or representatives. We have the right at our sole discretion to remove any content that, we feel in our judgment does not comply with this User Agreement, along with any content that we feel is otherwise offensive, harmful, objectionable, inaccurate, or violates any 3rd party copyrights or trademarks. We are not responsible for any delay or failure in removing such content. If you post content that we choose to remove, you hereby consent to such removal, and

consent to waive any claim against us.

We do not assume any liability for any content posted by you or any other 3rd party users of our website. However, any content posted by you using any open communication tools on our website, provided that it doesn't violate or infringe on any 3rd party copyrights or trademarks, becomes the property of Yoganta, and as such, gives us a perpetual, irrevocable, worldwide, royalty-free, exclusive license to reproduce, modify, adapt, translate, publish, publicly display and/or distribute as we see fit. This only refers and applies to content posted via open communication tools as described, and does not refer to information that is provided as part of the registration process, necessary in order to use our Resources. All information provided as part of our registration process is covered by our Privacy Policy.

You agree to indemnify and hold harmless www.theyoganta.com/www.yoganta.in a digital property of Yoganta and its parent company and affiliates, and their directors, officers, managers, employees, donors, agents, and licensors, from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, resulting from any violation of this User Agreement or the failure to fulfill any obligations relating to your account incurred by you or any other person using your account. We reserve the right to take over the exclusive defense of any claim for which we are entitled to indemnification under this User Agreement. In such event, you shall provide us with such cooperation as is reasonably requested by us.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Posting:</h3>
                    <p className='terms-of-use-policies-texts-para'>By posting, storing, or transmitting any content on the Website, you hereby grant Yoganta a perpetual, worldwide, non-exclusive, royalty-free, assignable, right and license to use, copy, display, perform, create derivative works from, distribute, have distributed, transmit and assign such content in any form, in all media now known or hereinafter created, anywhere in the world. Yoganta does not have the ability to control the nature of the user-generated content offered through the Website. You are solely responsible for your interactions with other users of the Website and any content you post. Yoganta is not liable for any damage or harm resulting from any posts by or interactions between users. Yoganta reserves the right, but has no obligation, to monitor interactions between and among users of the Website and to remove any content Yoganta deems objectionable, in Yoganta's sole discretion.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Disclaimer of Warranties:</h3>
                    <p className='terms-of-use-policies-texts-para'>Your use of this website and/or Services are at your sole risk. The website and Services are offered on an "as is" and "as available" basis. Yoganta Technologies Private Limited expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose and non infringement with respect to the Services or website content, or any reliance upon or use of the website content or Services (" Services" include trial Services.)

Without limiting the generality of the foregoing, Yoganta makes no warranty: that the information provided on this website is accurate, reliable, complete, or timely. that the links to third-party websites are to information that is

accurate, reliable, complete, or timely. no advice or information, whether oral or written, obtained by you from this website will create any warranty not expressly stated herein as to the results that may be obtained from the use of the products or that defects in products will be corrected. regarding any Services purchased or obtained through the website. Some jurisdictions do not allow the exclusion of certain warranties, so some of the above exclusions may not apply to you.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Limitation Of Liability</h3>
                    <p className='terms-of-use-policies-texts-para'>Yoganta Technologies Private Limited’s entire liability, and your exclusive remedy, in law, inequity, or otherwise, with respect to the website content / Services and/or for any breach of this agreement is solely limited to the amount you paid, less shipping and handling charges, for Services purchased via this website.

Yoganta will not be liable for any direct, indirect, incidental, special or consequential damages in connection with this agreement &/or Services in any manner, including liabilities resulting from: the use or the inability to use the website content / Services; the cost of procuring substitute content / Services; any information obtained / Services purchased or transactions entered into through the website; or any lost profits you allege.

Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages so some of the above limitations may not apply to you. conjunction with the Limitation of Liability as explained above, you expressly understand and agree that any claim against us shall be limited to the amount you paid, if any, for use of Services. www.theyoganta.com/www.yoganta.in Yoganta will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may be incurred by you as a result of using our Resources / Services, or as a result of any changes, data loss or corruption,

cancellation, loss of access, or downtime to the full extent that applicable limitation of liability laws apply.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Indemnification:</h3>
                    <p className='terms-of-use-policies-texts-para'>You will release, indemnify, defend and hold harmless Yoganta, and any of its contractors, agents, employees, officers, directors, shareholders, affiliates and assigns from all liabilities, claims, damages, costs and

expenses, including reasonable attorneys' fees and expenses, of third parties relating to or arising out of this Agreement or the breach of your warranties, representations, and obligations under this Agreement; the Website content or your use of the Website content; the Services or your use of the Services (including Trial Services); any intellectual property or other proprietary right of any person or entity; your violation of any provision of this Agreement; or any information or data you supplied to Yoganta.

When Yoganta is threatened with suit or sued by a third party, Yoganta may seek written assurances from you concerning your promise to indemnify Yoganta; your failure to provide such assurances may be considered by Yoganta  to be a material breach

of this Agreement. Yoganta will have the right to participate in any defense by you of a third-party claim related to your use of any of the Website content / Services, with the counsel of Yoganta’s choice at its expense. Yoganta Technologies Private Limited will reasonably cooperate in any defense by you of a third-party claim at your request and expense. You will have sole responsibility to defend Yoganta against any claim, but you must receive Yoganta’s prior written consent regarding any related settlement. The terms of this provision will survive any termination or cancellation of this Agreement or your use of the Website / Services.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Agreement To Be Bound</h3>
                    <p className='terms-of-use-policies-texts-para'>BY USING THIS WEBSITE OR ORDERING Services, YOU ACKNOWLEDGE THAT YOU HAVE READ AND AGREE TO BE BOUND BY THIS AGREEMENT AND ALL TERMS AND CONDITIONS ON THIS WEBSITE.

General Clause:

Force Majeure: Yoganta  will not be deemed in default hereunder or held responsible for any cessation, interruption or delay in the performance of its obligations hereunder due to earthquake, flood, fire, storm,

natural disaster, the act of God, war, terrorism, armed conflict, labor strike, lockout, or boycott.

Cessation of Operation: Yoganta may at any time, in its sole discretion and without advance notice to you, cease operation of the Website and distribution of the Services.

Entire Agreement: This Agreement comprises the entire agreement between you and Yoganta and supersedes any prior agreements pertaining to the subject matter contained herein.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Governing Law:</h3>
                    <p className='terms-of-use-policies-texts-para'>This website is controlled by Yoganta Technologies Private Limited from our offices located in the state of Telangana, India. It can be accessed by most countries around the world. As each country has laws that may differ from those of Telangana, India, by accessing our website, you agree that the statutes and laws of Telangana, India, without regard to its conflict of law principles to the contrary and the United Nations Convention on the International Sales of Goods, will apply to all matters relating to the use of this website and the purchase of any Services through this site.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Effect of Waiver:</h3>
                    <p className='terms-of-use-policies-texts-para'>The failure of Yoganta to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision and the other provisions of this Agreement remain in full force and effect.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Governing Law/Jurisdiction:</h3>
                    <p className='terms-of-use-policies-texts-para'>This Website originates from Hyderabad, Telangana, India. This Agreement will be governed by the laws of the State of Telangana, India. It can be accessed by most countries around the world. As each country has laws that may differ from those of Telangana, India without regard to its conflict of law principles to the contrary. Neither you nor Yoganta will commence Telangana), India. This Agreement will be governed by the laws of the State of Telangana, India. It can be accessed by most countries around the world. As each country has laws that may differ from those of Telangana, India without regard to its conflict of law principles to the contrary. Neither you nor or prosecute any suit, proceeding or claim to enforce the provisions of this Agreement, to recover damages for breach of or default of this Agreement, or otherwise arising under or by reason of this Agreement, other than in courts located in State of Telangana, India. It can be accessed by most countries around the world. As each country has laws that may differ from those of Telangana, India. By using this Website or ordering Services, you consent to the jurisdiction and venue of such courts in connection with any action, suit, proceeding or claim arising under or by reason of this Agreement. You hereby waive any right to trial by jury arising out of this Agreement and any related documents.

This website is controlled by Yoganta from our offices located in the state of Telangana, India. It can be accessed by most countries around the world. As each country has laws that may differ from those of Telangana, India, by accessing our website, you agree that the statutes and laws of Telangana, India without regard to the conflict of laws and the United Nations Convention on the International Sales of Goods, will apply to all matters relating to the use of this website and the purchase of any Services through this site. Furthermore, any action to enforce this User Agreement shall be brought in the courts having jurisdiction over such issue, located in Telangana, India. You hereby agree to the judgement passed by such courts and waive any right to the jurisdictional, venue, or inconvenient forum objections to such courts.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Statute of Limitation:</h3>
                    <p className='terms-of-use-policies-texts-para'>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Website or Services or this Agreement must be filed within one (1) year after such claim or cause of action arose else be forever barred.

Waiver of Class Action Rights:

BY ENTERING INTO THIS AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN CLAIM WITH THOSE OF OTHERS IN THE FORM OF A CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS ARISING OUT OF OR RELATING TO OR IN CONNECTION WITH THIS AGREEMENT MUST BE ASSERTED INDIVIDUALLY.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Termination:</h3>
                    <p className='terms-of-use-policies-texts-para'>Yoganta reserves the right to terminate your access to the Website if it reasonably believes, in its sole discretion, that you have breached any of the terms and conditions of this Agreement. Following termination, you will not be permitted to use the Website and Yoganta may, in its sole discretion and without advance

notice to you, cancel any outstanding orders for Services. If your access to the Website is terminated, Yoganta reserves the right to exercise whatever means it deems necessary to prevent unauthorized access to the Website. This Agreement will survive indefinitely unless and until Yoganta chooses, in its sole discretion and without advance notice to you, to terminate it.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Domestic Use:</h3>
                    <p className='terms-of-use-policies-texts-para'>Yoganta Technologies Private Limited makes no representation that the Website or Services are appropriate or available for use in locations outside India. Users who access the Website from outside India do so at their own risk and initiative and must bear all responsibility for compliance with any applicable local laws</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Cancellation And Refund:</h3>
                    <p className='terms-of-use-policies-texts-para'>Cancellation of an order is not possible once the payment has been made. No refunds will be given except in the event of cancellation or non-performance of service by Yoganta .</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Guarantee:</h3>
                    <p className='terms-of-use-policies-texts-para'>Unless otherwise expressed, Yoganta  & www.theyoganta.com/www.yoganta.in expressly disclaims all warranties and conditions of any kind, whether express or implied, including, but not limited to the implied warranties and conditions of merchantability, the fitness of content / Services for a particular purpose and non-infringement.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Assignment:</h3>
                    <p className='terms-of-use-policies-texts-para'>You may not assign your rights and obligations under this Agreement to anyone. Yoganta may assign its rights and obligations under this Agreement in its sole discretion and without advance notice to you.

BY USING THIS WEBSITE OR ORDERING Services FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.</p>
                </div>
                <div className='terms-of-use-policies-texts'>
                    <h3 className='terms-of-use-policies-texts-heading'>Contact Information:</h3>
                    <p className='terms-of-use-policies-texts-para'>If you have any questions or comments about these our Terms of Service as outlined above, you can contact us at: hello@yoganta.in</p>
                </div>
            </div>
            </section>
            <PunchLine message="Life is beautiful But *T&C apply*" />
        </div>
    );
}

export default TermsOfUse;