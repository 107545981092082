import React, { Component } from "react";
import Faq from "react-faq-component";

const general = {
    title: "General",
    rows: [
        {
            title:
                "How do I book a service?",
            content: 
            `
                You can book a service by visiting our website. Book your preferred service select a time slot of your convenience.
                You can also call our support team on 99859 55527.
                Our team might also call you before the visit.
            `,
        },
        {
            title:
                "How do I cancel/reschedule a service booked on Yoganta?",
            content: 
            `
                You do cancel/reschedule from the My Booking option on your dashboard. Or,
                Please do email us at hello@yoganta.in or call our support team on 99859 55527
            `,
        },
        {
            title:
                " Are your Service Providers qualified?",
            content: 
            `
                Absolutely yes! We only bring in professionals who have been recommended and certified by experts.
                We conduct interviews, check referrals, certifications, and do background checks on each and every service provider.
            `,
        },
        {
            title:
                " What if I am not happy with the service or the service provider?",
            content: 
            `
            What if I am not happy with the service or the service provider?
            Please report it to our support team at 99859 55527 or email us hello@yoganta.in.
            If you do have any complaints, suggestions, or feedback with regard to the service quality or our service provider, pricing, etc,
            `,
        },
        {
            title:
                "Can I pay online after a service?",
            content: 
            `
                Absolutely Yes!
                Please do call our support team on 99859 55527 or email us at hello@yoganta.in, We will arrange an online payment option for you.
            `,
        },
    ],
};

const live = {
    title: "Yoganta Live",
    rows: [
        {
            title:
                "What is YogantaLive",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
        {
            title:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
        {
            title:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
        {
            title:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
        {
            title:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
    ],
};

const home = {
    title: "Yoganta Home",
    rows: [
        {
            title:
                "What is YogantaHome",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
        {
            title:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
        {
            title:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
        {
            title:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
        {
            title:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
    ],
};


const cure = {
    title: "YogantaCure",
    rows: [
        {
            title:
                "What is YogantaCure",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
        {
            title:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
        {
            title:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
        {
            title:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
        {
            title:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit ?",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla nunc id convallis fermentum, mauris suspendisse eget. Odio tortor gravida tincidunt ullamcorper egestas. Blandit feugiat diam risus ultrices dignissim tincidunt semper nulla viverra.`,
        },
    ],
};

export default class Accordian extends Component {
    render() {
        return ( 
                <div>

                    <br/>
                    <br/>

                    <Faq
                        data={general}
                        styles={{
                            bgColor: "white",
                            rowTitleColor: "black",
                            rowTitleTextSize: "large",
                            rowContentColor: "black",
                            rowContentTextSize: "16px",
                            rowContentPaddingTop: "10px",
                            rowContentPaddingBottom: "10px",
                            rowContentPaddingLeft: "3%",
                            rowContentPaddingRight: "2%",
                            arrowColor: "black",
                            transitionDuration: "0.2s",
                            timingFunc: "linear",
                        }}
                    /> 
                    <br/>
                    <br/>

                    <Faq
                        data={live}
                        styles={{
                            bgColor: "white",
                            titleTextColor: "black",
                            rowTitleColor: "black",
                            rowTitleTextSize: "large",
                            rowContentColor: "black",
                            rowContentTextSize: "16px",
                            rowContentPaddingTop: "10px",
                            rowContentPaddingBottom: "10px",
                            rowContentPaddingLeft: "3%",
                            rowContentPaddingRight: "2%",
                            arrowColor: "black",
                            transitionDuration: "0.2s",
                            timingFunc: "linear",
                        }}
                    />     

                    <br/>
                    <br/>
                    
                    <Faq
                        data={home}
                        styles={{
                            bgColor: "white",
                            rowTitleColor: "black",
                            rowTitleTextSize: "large",
                            rowContentColor: "black",
                            rowContentTextSize: "16px",
                            rowContentPaddingTop: "10px",
                            rowContentPaddingBottom: "10px",
                            rowContentPaddingLeft: "3%",
                            rowContentPaddingRight: "2%",
                            arrowColor: "black",
                            transitionDuration: "0.2s",
                            timingFunc: "linear",
                        }}
                    /> 

                    <br/>
                    <br/>

                    <Faq
                        data={cure}
                        styles={{
                            bgColor: "white",
                            rowTitleColor: "black",
                            rowTitleTextSize: "large",
                            rowContentColor: "black",
                            rowContentTextSize: "16px",
                            rowContentPaddingTop: "10px",
                            rowContentPaddingBottom: "10px",
                            rowContentPaddingLeft: "3%",
                            rowContentPaddingRight: "2%",
                            arrowColor: "black",
                            transitionDuration: "0.2s",
                            timingFunc: "linear",
                        }}
                    /> 

                    <br/>
                    <br/>
                </div> 
        );
    }
}