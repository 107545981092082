import React,{useState} from 'react';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import './Shop.css';
import PunchLine from '../commonComps/PunchLine';

function Shop() {

  const [readMore,setReadMore]=useState(false);

  const extraContent=<div className="shop-Accessories-main-div-div">
              
              <div className='shop-card'>
                <div>
                <iframe title="28" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B099JTWQF7&asins=B099JTWQF7&linkId=3956ec3be89249c6e2e5cc6c5edaf964&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              <div className='shop-card'>
                <div>
                    <iframe title="27" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B07MNL598L&asins=B07MNL598L&linkId=c7c531d618c777a30187a02e25f9e2ff&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              
              <div className='shop-card'>
                <div>
                <iframe title="26" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B08DBJQP9Q&asins=B08DBJQP9Q&linkId=08173776065dacb9c4644ecee7774c3f&show_border=true&link_opens_in_new_window=true"></iframe>
               </div>
              </div>
              <div className='shop-card'>
                <div>
                <iframe title="25" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B097RMLPR2&asins=B097RMLPR2&linkId=4cf871ed7db57b286075fd29190af861&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
            
              <div className='shop-card'>
                <div>
                <iframe title="24" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B07KP5PNN7&asins=B07KP5PNN7&linkId=14a0a8fa16db510ddb50f2b67886ac67&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              <div className='shop-card'>
                <div>
                <iframe title="23" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B07BLSLWXT&asins=B07BLSLWXT&linkId=32197099879f99c011bfce4263c94eda&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
            </div>
  const linkName=readMore?'See less ↑':'See more ↓' 



    return (
      <div>
        {/* Metadata */}
          <Helmet>
            <title> Shop Yoga and Fitness Accessories- Yoganta</title>
            <meta name="description" content="Buy the best yoga and fitness accessories products from Amazon recommended by Yoganta, yoga gear including yoga mat, yoga block, yoga strap" />
            <link rel="canonical" href="https://www.yoganta.in/shop"/>
            <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
            <meta name="keywords" content="yoga and fitness accessories, best yoga mat on Amazon, jal nethi pot, yoga product" />
          
          </Helmet>
        {/* Metadata */}
        <section className='shop-heading'>
          <div className='shop-heading-main'>
            <h1>Affliate Recommended Products</h1>
          </div>
        </section>       

            <div className='shop-Accessories-main-div'>
            <div className='shop-Accessories-main-div-div'>
              <div className='shop-card'>
                <div>
                <iframe title="1" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=&language=en_IN&marketplace=amazon&region=IN&placement=B089NX722R&asins=B089NX722R&linkId=832f8ea107d54fb2d7e92331265d325a&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              <div className='shop-card'>
                <div>
                <iframe title="2" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B0716SYCRK&asins=B0716SYCRK&linkId=fb5e21c192556b8ad8cef38fbe48a97b&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              
              <div className='shop-card'>
                <div>
                <iframe title="3" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B01N6M7C2K&asins=B01N6M7C2K&linkId=37cf89a20008edceb56bf8d284d52661&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              <div className='shop-card'>
                <div>
                <iframe title="4" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B08HS4FYX2&asins=B08HS4FYX2&linkId=2de15ff6b30b2975109fd0cb8777b5f2&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>

              <div className='shop-card'>
                <div>
                <iframe title="5" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B097RMLPR2&asins=B097RMLPR2&linkId=4cf871ed7db57b286075fd29190af861&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              
              <div className='shop-card'>
                <div>
                  <iframe title="6" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B01FN7XA38&asins=B01FN7XA38&linkId=4f996b7a5588a2cb0b950f33ec75dc2a&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              <div className='shop-card'>
                <div>
                  <iframe title="7" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B07JC28W27&asins=B07JC28W27&linkId=e016ef454f243aa2af9e538ef713ad3e&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              <div className='shop-card'>
                <div>
                  <iframe title="8" width="120" height="240"  marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B08442K197&asins=B08442K197&linkId=f38d26717dd8bef6bf229032eb5af191&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
             
              {/* <div className='shop-card'>
                <div>
                  <iframe title="9" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B08PK8Z4JL&asins=B08PK8Z4JL&linkId=2d57cec556dc176a6b3f6cd3d4034703&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div> */}
              <div className='shop-card'>
                <div>
                <iframe title="10" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B07SM79MH3&asins=B07SM79MH3&linkId=a0381f47e299d63361f370b6a073b27a&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              
              <div className='shop-card'>
                <div>
                <iframe title="11" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B08JQBRFKJ&asins=B08JQBRFKJ&linkId=e5bcb48c9756b32cf1025fa1c2cc7def&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              <div className='shop-card'>
                <div>
                <iframe title="12" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B07DSRZY88&asins=B07DSRZY88&linkId=e2e589216f7f64a62c0a00b26cc8a22a&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              {/* <div className='shop-card'>
                <div>
                <iframe title="13" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B01HJD8318&asins=B01HJD8318&linkId=9047384032c9972ee43f9f254e612879&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div> */}
              <div className='shop-card'>
                <div>
                  <iframe title="14" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B07QGDSDMN&asins=B07QGDSDMN&linkId=f006a727bd244e7e8d7d65243e6aa9d4&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              <div className='shop-card'>
                <div>
                <iframe title="15" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B091F3Z2PK&asins=B091F3Z2PK&linkId=1fe0862927305a009002f7c73c1fae74&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              <div className='shop-card'>
                <div>
                <iframe title="16" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B07ZTL6BPW&asins=B07ZTL6BPW&linkId=577e478a7774457892fc0eab4150cc01&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              <div className='shop-card'>
                <div>
                  <iframe title="17" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B08HVF8CC7&asins=B08HVF8CC7&linkId=a28adc68f45546aab8fca89e17576f45&show_border=true&link_opens_in_new_window=true"></iframe>
               </div>
              </div>
              {/* <div className='shop-card'>
                <div>
                  <iframe title="18" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B08LNKVHD5&asins=B08LNKVHD5&linkId=1125be61a958b13a223e3a831574d6d8&show_border=true&link_opens_in_new_window=true"></iframe>
               </div>
              </div> */}
              <div className='shop-card'>
                <div>
                <iframe title="19" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B089WG1RM7&asins=B089WG1RM7&linkId=d053aaf5fc8e38f6dc7735448d383df0&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              {/* <div className='shop-card'>
                <div>
                <iframe title="20" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B00MDAN5SE&asins=B00MDAN5SE&linkId=8f5873d1b3db235b122cc9814fded39f&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div> */}
              <div className='shop-card'>
                <div>
                <iframe title="21" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B08S47NTL6&asins=B08S47NTL6&linkId=a192616547cda7f1b03b5ebfc4f36da5&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
              <div className='shop-card'>
                <div>
                  <iframe title="22" width="120" height="240" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=theyoganta-21&language=en_IN&marketplace=amazon&region=IN&placement=B07VTMFTNH&asins=B07VTMFTNH&linkId=06a02b66bbda0ad962d63cead4527d23&show_border=true&link_opens_in_new_window=true"></iframe>
                </div>
              </div>
            </div>
            {readMore && extraContent}
            <button className="shop-Accessories-btn" type="button" onClick={()=>{setReadMore(!readMore)}} data-toggle="collapse" data-target="#moreimages" aria-expanded="false" aria-controls="moreimages">
          <h3>{linkName}</h3>
        </button>
            </div>

            


        <PunchLine message="The yoga pose you avoid the most is the one you need the most"/>
      </div>
    );
  }
  
  export default Shop;