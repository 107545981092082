import React, { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
import './leadForm.css';
import '../../events/YogaChallenge.css';
init("user_C6Cxq7xvOGU6trlNiF70j");


export default function LeadForm (){

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
    
  const form = useRef();
  const navigate = useNavigate();

  function sendEmail(e) {
    e.preventDefault();
    navigate ("/thank-you");

    emailjs.sendForm('service_szd4b0d', 'template_kf2cvir', form.current, 'user_C6Cxq7xvOGU6trlNiF70j')
    .then((result) => {
        // console.log(result.text);
        alert('Submitted Successfully');
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset()
  };

  return (
 <>
      <Button className= "buttonLead" id="btn-lead-form" onClick={handleShow}>
      START YOUR FREE TRIAL
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className= "popup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="popup-header">Looking forward to see you</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="lead-form" ref={form} onSubmit={sendEmail} autocomplete="off">
                <div className="field">
                  <label for="Name">Name</label>
                  <input type="text" name="Name" id="Name" maxlength="25" placeholder="Enter Name" autofocus required />
                </div>
                <div className="field">
                  <label for="Phone">Phone</label>
                  <input type="tel" name="Phone" id="Phone" maxlength="10" pattern="[6-9]{1}[0-9]{9}" placeholder="Enter 10 digits Phone" required />
                </div>
                <div className="field">
                  <label for="Email">Email</label>
                  <input type="text" name="Email" id="Email" pattern="[^ @]*@[^ @]*" placeholder="Enter Email Address" required />
                </div>
                {/* <div class="field">
                  <label for="City">City</label>
                  <input type="text" name="City" id="City" placeholder="Enter City"/>
                </div> */}

                <input type="submit" id="button" className= "buttonLead" value="START YOUR FREE TRIAL" Close/>
              </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};