import React,{useState} from 'react';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import yogantaCureImg from "../../assets/yoganta-cure.png";
import femaleAvatarImg from "../../assets/female-2.png";
import femaleAvatarImg1 from "../../assets/female-1.png";
import maleAvatarImg1 from "../../assets/male-1.png";
import maleAvatarImg2 from "../../assets/male-2.png";
import hyderabad from "../../assets/Hyderabad-y.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PunchLine from '../commonComps/PunchLine';
import './yogantaCure.css'

const YogantaCure=()=>{


  const [readMore,setReadMore]=useState(false);

  const extraContent=<div className="yoganta-cure-success-stories">
      <div className='yoganta-cure-seccess-stories-testimonial'>
          <img src={maleAvatarImg1} alt="maleAvatar1" />
          <div className='yoganta-cure-seccess-stories-testimonial-text'>
            <h3>Paramesh Niral</h3>
            <p>Controlled Diabetes</p>
            <p>
              I have never done yoga before, but this Yogantcure program helped me a lot in controlling my diabetes. 
              Now yoga has become a part of my life and I am more conscious of my food.
            </p>
          </div>
      </div>
      <div className='yoganta-cure-seccess-stories-testimonial'>
        <img src={maleAvatarImg2} alt="maleAvatar2" />
        <div className='yoganta-cure-seccess-stories-testimonial-text'>
          <h3>Anish Yogesh</h3>
          <p>Relieved Shoulder Pain</p>
          <p>
            YoganataCure program helped me recover from chronic shoulder pain. 80% shoulder pain is gone, 
            and now I am able to live a normal lifestyle.
          </p>
        </div>
      </div>
  </div>
  const linkName=readMore?'See less ↑':'See more ↓'



  return(
    <div className='yoganta-cure-main'>
     {/* Metadata */}
     <Helmet>
        <title>Therapeutic Yoga | Holistic Healing - YogantaCure</title>
        <meta name="description" content="YogantaCure - Say No to pain and Hello to life! Therapeutic yoga, Holistic healing for chronic lifestyle disorders like Diabetes, PCOS, Sinusitis, Asthma, Cervical Spondylosis, Depression, Thyroid" />
        <link rel="canonical" href="https://www.yoganta.in/yogantaCure"/>
		    <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
		    <meta name="keywords" content=" yogantaCure, yoga therapy, therapeutic yoga in Hyderabad, therapeutic yoga for Diabetes, PCOS, Sinusitis, Asthma, Cervical Spondylosis, Depression, Thyroid" />
      
      </Helmet>
    {/* Metadata */}
      <section className='yoganta-cure-first-sec'>
          <div className='yoganta-cure-first-sec-text'>
              <h1>Yoganta<span>Cure</span></h1>
              {/* <h3>Say No to pain and Hello to life!</h3> */}
              <h3>A Holistic and Integrated Approach to Lifestyle Diseases</h3>
              <p>Not all chronic pain can be cured but it can be managed in such a way that
               you are able to lead a normal lifestyle</p>
          </div>
          <div className='yoganta-cure-first-sec-img'>
              <img src={yogantaCureImg} alt="yogantaCure hero banner" />
          </div>
      </section>

      <section className='yoganta-cure-how-we-do'>
        <div className='yoganta-cure-how-we-do-main'>
          <h2>How do we do?</h2>
          <p>YogantaCure constitutes a team of holistic practitioners who are certified and specialize in a wide range of alternative medicine like Yoga, Ayurveda, Chiropractic, Diet & Nutrition, Homeopathy, Sujok, etc.
          who will curate a holistic customized routine for you And this routine will enable you to lead a normal lifestyle</p>
        </div>
      </section>

      <section className='yoganta-cure-composite-solution'>
        <div className='yoganta-cure-composite-solution-heading'>
          <h2>Holistic Healing </h2>
        </div>
        <div className='yoganta-cure-composite-solution-solutions'>
          <div className='yoganta-cure-composite-solution-solutions-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Diabetes</h3></div>
          </div>
          <div className='yoganta-cure-composite-solution-solutions-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Hypertension</h3></div>
          </div>
          <div className='yoganta-cure-composite-solution-solutions-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Obesity</h3></div>
          </div>
          <div className='yoganta-cure-composite-solution-solutions-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Depression</h3></div>
          </div>
          <div className='yoganta-cure-composite-solution-solutions-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>PCOS</h3></div>
          </div>
          <div className='yoganta-cure-composite-solution-solutions-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Asthma</h3></div>
          </div>
          <div className='yoganta-cure-composite-solution-solutions-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Sinusitis</h3></div>
          </div>
          <div className='yoganta-cure-composite-solution-solutions-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Hypothyroidism</h3></div>
          </div>
          <div className='yoganta-cure-composite-solution-solutions-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Migraine</h3></div>
          </div>
          <div className='yoganta-cure-composite-solution-solutions-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Back Pain</h3></div>
          </div>
          <div className='yoganta-cure-composite-solution-solutions-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Knee pain</h3></div>
          </div>
          <div className='yoganta-cure-composite-solution-solutions-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Cervical Spondylosis</h3></div>
          </div>
        </div>
      </section>

      <section className='yoganta-cure-success-stories'>
        <div className='yoganta-cure-seccess-stories-heading'>
          <h2>Success Stories</h2>
        </div>
        <div className='yoganta-cure-seccess-stories-testimonial'>
          <img src={femaleAvatarImg} alt="femaleAvatarImg" />
          <div className='yoganta-cure-seccess-stories-testimonial-text'>
            <h3>Vritti Chandan</h3>
            <p>Lost weight</p>
            <p>I'm thrilled, since starting YogantaCure’s programme I've lost 6kg in weight and dropped 2 dress sizes. I'd recommend this programme to anyone.</p>
          </div>
        </div>
        <div className='yoganta-cure-seccess-stories-testimonial'>
          <img src={femaleAvatarImg1} alt="femaleavatarImg1" />
          <div className='yoganta-cure-seccess-stories-testimonial-text'>
            <h3>Tanika Nagi</h3>
            <p>Controlled Diabetes</p>
            <p>
              I would like to say thank you to the entire team of Yoganta. I have already seen amazing results,
              my diabetes level has gone down significantly since I started doing yoga along with the diet plan.
              Thanks, guys, keep up the good work!
            </p>
          </div>
        </div>
        {readMore && extraContent}
        <button className="life-yoganta-btn" type="button" onClick={()=>{setReadMore(!readMore)}} data-toggle="collapse" data-target="#moreimages" aria-expanded="false" aria-controls="moreimages">
          <h3>{linkName}</h3>
        </button>
      </section>

      <section className='yoganta-cure-avialable-in'>
        <div className='yoganta-cure-avialable-in-heading'>
          <h2>Available in</h2>
        </div>
        <div className='yoganta-cure-avialable-in-icons'>
            <div className='yoganta-cure-avialable-in-icon'><img src={hyderabad} alt='hyderabad-city-icon'></img></div>
            <div className='yoganta-cure-avialable-in-icon-heading'>Hyderabad</div>
        </div>
      </section>

      <section className='yoganta-home-get-started'>
        <div className='yoganta-home-get-started-main'>
          <p>To get started, Contact us at <br /><a href="mailto:hello@yoganta.in">hello@yoganta.in</a> or <a href="tel:+919985955527">+91 99859 55527</a></p>
        </div>
      </section>

      <PunchLine message="Take Care of Your Well-Being"/>
    </div>
  );
}

export default YogantaCure;