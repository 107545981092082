import React from 'react';
import './LPTestimonial.css';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const LPTestimonial = () => {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1025 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 469 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 468, min: 0 },
          items: 1
        }
      };

    return(
        <section className='lptestimonial'>
        <div className='lptestimonial-main'>
            <h2>Testimonials</h2>
        </div>
            <Carousel responsive={responsive} showDots={true} removeArrowOnDeviceType={["mobile"]}>
            <div className='lptestimonial-main-text'>
                <p>After starting yoga, I am completely changed! I feel like all aspects of my life have changed. My relationships, my family, my health, everyone has felt a positive change.</p>
                <div className='lptestimonial-main-text-name'>
                    <p>-Anahita Virani</p>
                    <p>Teacher</p>
                </div>
            </div>
            <div className='lptestimonial-main-text'>
                <p>The best yoga class I've ever joined and I wish I found it sooner! It has helped me find immense clarity in my life and empowered me to be a better person. Everyone needs a Yoganta!</p>
                <div className='lptestimonial-main-text-name'>
                    <p>-Uditi Rachna</p>
                    <p>Interior Designer</p>
                </div>
            </div>
            <div className='lptestimonial-main-text'>
                <p>Yoganta has been been a game changer for me! The course has taught me life skills that are making me healthier, happier and more productive than ever before. For those of you who want to start yoga, I highly recommend joining Yoganta.</p>
                <div className='lptestimonial-main-text-name'>
                    <p>-Aakar Murli</p>
                    <p>Business man</p>
                </div>
            </div>
            <div className='lptestimonial-main-text'>
                <p>I can honestly say it was the best decision I've ever made for my health. I love doing yoga. The trainers are very experienced, kind, and helping me live a healthy lifestyle.</p>
                <div className='lptestimonial-main-text-name'>
                    <p>-Shubhangi Prasai</p>
                    <p>Housewife</p>
                </div>
            </div>
            </Carousel>
        </section>
    )
}

export default LPTestimonial;