import React from 'react';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import './Contact.css';
import YogantaLogoBlue from "../../assets/yoganta-logo-blue.jpg";
// import YogantaOG from "../../assets/og-image.jpg";
// import ContactBanner from "../../assets/contact.jpg";
import YouTubeIcon from '@mui/icons-material/YouTube';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import PunchLine from '../commonComps/PunchLine';

function Contact() {
  return (
    <div className="contact">
    {/* Metadata */}
     <Helmet>
        <title>Contact Us - Yoganta</title>
        <meta name="description" content="We are here for you! Phone : +919985955527 email: hello@yoganta.in" />
        <link rel="canonical" href="https://www.yoganta.in/contact"/>
		    <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
		    <meta name="keywords" content="yoganta contact, yoganta Hyderabad, +919985955527, 9985955527, hello@yoganta.in, yoganta phone number, yoganta email id, yoganta contact number, yoganta technologies private limited contact" />
      
      </Helmet>
    {/* Metadata */}
      <div className="contact-main">
      <div className="contact-main-main">
        <div className="contact-main-texts">
          <h1>We are here for you!</h1>
          <p className="contact-main-texts-paraOne">Let us know what you think. We'd love to hear from you.</p> <hr /><br />
          {/* <p>PHONE : +91-99859 55527</p> */}
          <p>Phone : <a href="tel:+919985955527">+91 99859 55527</a></p>
          {/* <p>EMAIL : hello@theyoganta.com</p> */}
          <p>Email : <a href="mailto:hello@yoganta.in">hello@yoganta.in</a></p>
          {/* <p>Address : Hyderabad, Telangana, India</p><br /> */}
          <p>Address : Leeway, 2/91/20, BP Raju Marg, Laxmi Cyber City, Whitefields, Kondapur, Hyderabad - 500081, Telangana, India</p><br />
          <p>Connect With Us</p>
          <div className="contact-main-texts-links">
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://www.facebook.com/TheYoganta/" target="_blank"><FacebookIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://www.instagram.com/theyoganta/" target="_blank"><InstagramIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://twitter.com/TheYoganta" target="_blank"><TwitterIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://www.linkedin.com/company/theyoganta" target="_blank"><LinkedInIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://in.pinterest.com/theyoganta/" target="_blank"><PinterestIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://www.youtube.com/channel/UCdgJhbumN3X6hRBmndCSyaQ" target="_blank"><YouTubeIcon /></a>
          </div>
        </div>
       {/* <div className="contact-main-img">
              <img src={ContactBanner} alt="Contact" />
        </div>  */}

        <div className="contact-main-img">
         <img  id="yoganta-logo" src={YogantaLogoBlue} alt="Yoganta logo" />
          <iframe title="GoogleMap" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15224.333876618028!2d78.3655302!3d17.4557186!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb81b95c934d0a5af!2sYoganta!5e0!3m2!1sen!2sin!4v1657101129942!5m2!1sen!2sin" width="540" height="400" marginWidth="0" marginHeight="0" scrolling="no" frameBorder="0" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div> 
      </div>
      </div>
      <PunchLine message="We listen and act"/>
    </div>
  );
}

export default Contact;