import React from "react";
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import pricingImg from "../../assets/pricing.jpeg";
// import founder from "../../assets/yoganta-founder-img.webp";
import RaushanYoganta from "../../assets/RaushanYoganta.jpg";
// import Tungasri from "../../assets/Tungasri.jpg";
import Nishi from "../../assets/Nishi-Nutritionist.webp";
import Kaavya from "../../assets/Kaavya.webp";
import Meena from "../../assets/Meena.webp";
import Anjali from "../../assets/Anjali-singh.jpeg";
// import Yamuna from "../../assets/Yamuna-crop.webp";
import Tanisha from "../../assets/tanisha.jpeg";
import Amrutha from "../../assets/amrutha.jpeg";
import Chakradhar from "../../assets/Chakradhar.jpg";
import Umar from "../../assets/Umar.jpg";
import Jahnavi from "../../assets/Jahnavi.jpg";
import Sravani from "../../assets/Sravani.PNG";
import Gaurav from "../../assets/Gaurav.jpg";
import Poorti from "../../assets/Poorti.jpg";
import "./About.css";
import PunchLine from "../commonComps/PunchLine";
import { NavLink } from "react-router-dom";

function About() {
	return (
		<div>
			{/* Metadata */}
				<Helmet>
					<title>About Us - Yoganta</title>
					<meta name="description" content="Welcome to Yoganta. we are here for you. To serve and support you on your path of self-discovery, to become the highest version of yourself, to step into your power and your full potential." />
					<link rel="canonical" href="https://www.yoganta.in/about"/>
					<meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
					<meta name="keywords" content="yoganta, yoganta Hyderabad, yoganta technologies private limited, Raushan Kumar, Raushan Yoganta, Yoganta founder, yoganta team" />
     			</Helmet>
    		{/* Metadata */}
			<section className="about-new-top">
				<div className="about-new-top-head">
					<h1 className="about-new-top-head-heading">Welcome to Yoganta</h1>
					<p className="about-new-top-head-para">
					We are here for you. To serve and support you on your path of self-discovery, 
					to become the highest version of yourself, to step into your power and your full potential.
					</p>
				</div>
			</section>

			<section className="about-new-second-sec">
				<div className="about-new-second-sec-div">
					<img className="about-new-second-sec-div-img" src={pricingImg} alt="img-cant-load" />
					<div className="about-new-second-sec-div-texts">
						<h3 className="about-new-second-sec-div-texts-title">Who We Are?</h3>
						<p className="about-new-second-sec-div-texts-para">
						Yoganta is a group of passionate practitioners of holistic wellness from Yoga, Fitness, Nutrition, Ayurveda, Naturopathy, Chronic Pain Management.
						 Who love to serve you to live your healthy lifestyle.
						</p>
					</div>
				</div>
			</section>

			<section className="about-new-third-sec">
				<div className="about-new-third-sec-main">
					<div className="about-new-third-sec-main-div">
						<h3 className="about-new-third-sec-main-div-title">Our Mission</h3>
						<p className="about-new-third-sec-main-div-para">
						To align indigenous yoga, alternative medicine with the mainstream health system.
						</p>
					</div>
					<div className="about-new-third-sec-main-div">
						<h3 className="about-new-third-sec-main-div-title">Our Vision</h3>
						<p className="about-new-third-sec-main-div-para">
						To be a one-stop holistic treatment provider for lifestyle diseases.
						</p>
					</div>
				</div>
			</section>

			<section className="about-new-fourth-sec">
				<div className="about-new-fourth-sec-main">
					<div className="about-new-fourth-sec-main-div-img">
						<img src={RaushanYoganta} alt="Yoganta founder Raushan Kumar"></img>
					</div>
					<div className="about-new-fourth-sec-main-div">
						<h3 className="about-new-fourth-sec-main-div-title">Meet the Founder</h3>
						<br />
						<h4>Raushan Kumar</h4> <br/>
						{/* <p>Yoga Instructor | Sujok Therapist | Reiki Practitioner | Techie | Digital Marketer</p> */}
						<p className="about-new-fourth-sec-main-div-para">
							Better known as Raushan Yoganta. He is a dedicated Yogi, Techie, Digital Marketer, Wellness Coach.
							A passionate practitioner of holistic wellness. Practices Yoga, Sujok, and Reiki.
						
							Raushan's first priority is from the time he came up with the idea of ​promoting and
							empowering practitioners of holistic wellness with the aim of bridging the gap between alternative medicine and modern 
							medicine with complete dedication towards it.
						</p>
					</div>
				</div>
			</section>

			<section className="about-new-fifth-sec">
				<div className="about-new-fifth-sec-main">
					<div className="about-new-fifth-sec-main-title">Meet the Team</div>
					<div className="about-new-fifth-sec-main-div">
						<div className="about-new-fifth-sec-main-div-card">
							<img src={Chakradhar} alt="Chakradhar Reddy" />
							<p>O. Chakradhar Reddy</p>
							<p>Cofounder & COO</p>
						</div>
						<div className="about-new-fifth-sec-main-div-card">
							<img src={Umar} alt="K.M. Umar Shareef" />
							<p>K.M. Umar Shareef</p>
							<p>Business Development Executive</p>
						</div>
						<div className="about-new-fifth-sec-main-div-card">
							<img src={Gaurav} alt="Yoganta Digital Marketer Gaurav Chikte" />
							<p>Gaurav Chikte</p>
							<p>Digital Marketing</p>
						</div>
						<div className="about-new-fifth-sec-main-div-card">
							<img src={Poorti} alt="Yoganta Content Writer Poorti Kaur" />
							<p>Poorti Kaur</p>
							<p>Content Writing</p>
						</div>
						<div className="about-new-fifth-sec-main-div-card">
							<img src={Jahnavi} alt="Yoganta Yoga Trainer Sri Jahnavi" />
							<p>Sri Jahnavi</p>
							<p>Yoga Instructor</p>
						</div>
						<div className="about-new-fifth-sec-main-div-card">
							<img src={Sravani} alt="Yoganta Yoga Trainer Sravani Reddy" />
							<p>Sravani Reddy</p>
							<p>Yoga Instructor</p>
						</div>
						<div className="about-new-fifth-sec-main-div-card">
							<img src={Tanisha} alt="Yoganta Yoga Trainer Tanisha Agrawal" />
							<p>Tanisha Agrawal</p>
							<p>Yoga Instructor</p>
						</div>
						<div className="about-new-fifth-sec-main-div-card">
							<img src={Nishi} alt="Nishi" />
							<p>Nishi Gupta</p>
							<p>Nutritionist</p>
						</div>
						<div className="about-new-fifth-sec-main-div-card">
							<img src={Meena} alt="Meena" />
							<p>Meena Jethi</p>
							<p>Reiki Master</p>

						</div>
						<div className="about-new-fifth-sec-main-div-card">
							<img src={Kaavya} alt="Kaavya" />
							<p>Kaavya Yedla</p>
							<p>Yoga Instructor</p>
						</div>
						<div className="about-new-fifth-sec-main-div-card">
							<img src={Anjali} alt="Anjali Singh Rathore" />
							<p>Anjali Singh Rathore</p>
							<p>Yoga Instructor</p>
						</div>
						<div className="about-new-fifth-sec-main-div-card">
							<img src={Amrutha} alt="Yoganta Yoga Trainer Amrutha Sharma" />
							<p>Amrutha Sharma</p>
							<p>Yoga Instructor</p>
						</div>
						{/* <div className="about-new-fifth-sec-main-div-card">
							<img src={maleAvatar} alt="img-cant-load" />
							<p>Arjun Ram</p>
							<p>Product Designer</p>
						</div>
						<div className="about-new-fifth-sec-main-div-card">
							<img src={femaleAvatar} alt="img-cant-load" />
							<p>Arjun Ram</p>
							<p>Product Designer</p>
						</div> */}
					</div>
				</div>
			</section>

			<section className="about-new-sixth-sec">
				<div className="about-new-sixth-sec-main">
					<div className="about-new-sixth-sec-main-div">
						<h3 className="about-new-sixth-sec-main-div-title">We’re hiring!</h3>
						<p className="about-new-sixth-sec-main-div-para">
						Make the best career move.
							<br />
							APPLY NOW
						</p>
					</div>
					<div className="about-new-sixth-sec-main-div">
						<NavLink to="/careers">
							<button type="button" className="career-btn">See the openings</button>
						</NavLink>
					</div>
				</div>
			</section>

			<PunchLine message="Helping You Find Peace Within Yourself" />
		</div>
	);
}

export default About;
