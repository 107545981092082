import React from 'react';
import './JobOpening.css';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Helmet} from "react-helmet";

function CorporateSalesManager() {
    return (
      <div>
      {/* Metadata */}
      <Helmet>
            <title>Careers - Yoganta</title>              
      </Helmet>
      {/* Metadata */}
      <section className="jobopening-top">
      <div>
        <h1 className="page_name">We're Hiring</h1>
      </div>
      </section>
      
      <section id='middle-part'>
          {/* <div className='back-to-openings'>
          <ArrowBackIosIcon />
            <span>Back to all openings</span>
          </div> */}
          <div className='main-content'>
              <h1 className='middle-heading'>Corporate Sales Manager</h1>
              <p>Hyderabad, Bengaluru, New Delhi | Full-Time</p>
              <hr />

              <div className='opening-paras'>
                  <h5>Job Role:</h5>
                  <p>
                  Corporate sales manager to reach out to customers through emails and phone calls to offer them products and services and responsible for directing and supervising sales operations to ensure profit maximization and realization of sales targets.
                  </p>
                  {/* <p>{data.job_role_p_2}</p> */}
              </div>

              <div className='opening-paras'>
                  <h5>Responsibilities:</h5>
                  <ul>
                    <li>
                    This is a front-line Sales position in the Fitness Services Sales team, and is responsible for driving business, achieving monthly sales targets and acquiring new customers through consultative selling of our corporate services
                    </li>
                    <li>
                    Prospecting for new customers through existing leads and self-generated by cold calling and maximizing lead generation
                    </li>
                    <li>
                    Drive the on-boarding of new customers and initiates spend enablement activities
                    </li>
                    <li>
                    Partnering with the marketing, advertising, and production heads to work out strategies and plans for enhancing the overall sales of the company
                    </li>
                    <li>
                    Be the interface between Yoganta and the customer to resolve any client enrollment processing issues
                    </li>
                  
                  </ul>
              </div>

              <div className='opening-paras'>
                  <h5>Skills and Qualifications:</h5>
                  <ul>
                    <li>
                    Any Graduate having relevant experience in sales and marketing
                    </li>
                    <li>
                    Consultative selling experience
                    </li>
                    <li>
                    Excellent verbal and written communication, relationship building experience in developing and executing successful sales strategies
                    </li>
                    <li>
                    Ability to influence and negotiate with customers both internally and externally and at all levels
                    </li>
                    <li>
                    Ability to establish/maintain credibility with customers and partners
                    </li>
                  </ul>
              </div>

              <div className='after-opening-paras'>
              <p>If you feel like you have the skills and if you feel inspired by our mission, we would love to review your application.</p>
              </div>
          </div>
      </section>

      <section className='last-sec'>
        <h1>To apply, write to us at <a href="mailto:hello@yoganta.in">hello@yoganta.in</a> with your profile</h1>
      </section>
      
      


      </div>
    );
  }
  
  export default CorporateSalesManager;
