import React, { useState } from "react";
import "./Popup.css";
import { CgCheckO } from "react-icons/cg";
import Prices from "../Prices";

const PopupStatic = () => {
  const [plans, setPlans] = useState([
    { id: 1, duration: 6, original: 13200, acutal: 8640 },
    { id: 2, duration: 3, original: 6600, acutal: 4920 },
    { id: 3, duration: 1, original: 2200, acutal: 1960 },
    { id: 4, duration: 1, acutal: 95 },
  ]);
  const newWindow = () => {
    const planId=planLink()
    return window.open(`${planId}`,"_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=350,width=900,height=600")
    
  }
  const [isActive, setIsActive] = useState(1);
  const planLink = () =>{
      if (isActive===1){
          return  'https://rzp.io/l/yoganta-live-6-months-plan'
      }
      else if (isActive===2){
          return  'https://rzp.io/l/yoganta-live-3-months-plan'
      }
      else if (isActive===3){
        return  ' https://rzp.io/l/yoganta-live-monthly-plan'
      }
      else{
        return  'https://rzp.io/l/yoganta-live-demo'
      }
  }
  const handelClick = (id) => {
    setIsActive(id);
  };

  return (
      <div className="PopupStatic">

        <div className="Pricing">
          <div className="main-section">
            <div className="content-main">
              <h3>YOGANTA LIVE</h3>
              <h4>Benefits</h4>
              <div className="content">
                <div className="content-sub">
                  <h5>
                    <CgCheckO />&nbsp;
                    Unlimited Group Classes
                  </h5>
                  <h5>
                    <CgCheckO />&nbsp;
                    Pause Membership
                  </h5>
                </div>
                <div className="content-sub">
                  <h5>
                    <CgCheckO />&nbsp;
                    Flexible Schedules
                  </h5>
                  <h5>
                    <CgCheckO />&nbsp;
                    Free Diet Consultation
                  </h5>
                </div>
              </div>
            </div>
            <div className="payment-main">
              <h3>Choose subscription plan</h3>
              {plans.map((plan) => {
                if (isActive === plan.id) {
                  return (
                    <div key={plan.id}>
                      <Prices
                        id={plan.id}
                        isActive={true}
                        duration={plan.duration}
                        original={plan.original}
                        acutal={plan.acutal}
                        handelClick={handelClick}
                      />
                      <hr />
                    </div>
                  );
                } else {
                  return (
                    <div key={plan.id}>
                      <Prices
                        id={plan.id}
                        isActive={false}
                        duration={plan.duration}
                        original={plan.original}
                        acutal={plan.acutal}
                        handelClick={handelClick}
                      />
                      <hr />
                    </div>
                  );
                }
              })}
            
              <button className="booknow" onClick={() => newWindow()}> 
                  PAY &nbsp;₹
                    {plans.map((plan) => {
                    if (plan.id === isActive) return plan.acutal;
                    })}
            </button>
            </div>
          </div>
        </div>
        </div>
  );
};

export default PopupStatic;
