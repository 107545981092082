import React,{useState} from 'react';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import yogantaLiveImg from "../../assets/yogantaLive-banner.jpeg";
import yogantaLive1 from "../../assets/yogantaLive1.webp";
import yogantaLive2 from "../../assets/yogantaLive2.webp";
import yogantaLive3 from "../../assets/yogantaLive3.webp";
import yogantaLiveCollage1 from "../../assets/yogantaLive-collage1-min.png";
import yogantaLiveCollage2 from "../../assets/yogantaLive-collage2-min.png";
import yogantaLiveCollage3 from "../../assets/yogantaLive-collage3-min.png";
import yogantaLiveCollage4 from "../../assets/yogantaLive-collage4-min.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import MailIcon from '@mui/icons-material/Mail';
import PunchLine from '../commonComps/PunchLine';
import './YogantaLive.css';
import Popup from '../pricing/Popup/Popup';
// import WebsiteLeadForm from '../../../src/components/landingPages/leadForm/WebsiteLeadForm';
// import WebsiteLeadForm from '../landingPages/leadForm/WebsiteLeadForm';
import WebsiteLeadForm from '../../components/landingPages/leadForm/WebsiteLeadForm';

const YogantaLive=()=>{

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [readMore,setReadMore]=useState(false);

  const extraContent=<div className="life-yoganta">
  
      <img src={yogantaLiveCollage1} alt="yoganta Live collage for online yoga 4" />
      <img src={yogantaLiveCollage2} alt="yoganta Live collage for online yoga 5" />
      <img src={yogantaLiveCollage3} alt="yoganta Live collage for online yoga 5" />
      <img src={yogantaLiveCollage4} alt="yoganta Live collage for online yoga 5" />
      <img src={yogantaLive2} alt="yoganta Live 2" />
      <img src={yogantaLive3} alt="yoganta Live 3" />
  </div>
  const linkName=readMore?'See less ↑':'See more ↓'

  return(
    <div className='yoganta-home-main'>
    {/* Metadata */}
    <Helmet>
        <title>Live Online Yoga Classes - YogantaLive</title>
        <meta name="description" content="YogantaLive - Practice Yoga Anytime You Choose!, Unlimited Live Interactive Classes, Flexible schedules from 6 am to 8 pm IST, Live 1 on 1 Class, Dedicated therapeutic yoga" />
        <link rel="canonical" href="https://www.yoganta.in/yogantaLive"/>
		    <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
		    <meta name="keywords" content=" yogantaLive, online yoga, therapeutic yoga, online yoga group classes, online yoga in Hyderabad, benefits of online yoga" />
      
      </Helmet>
    {/* Metadata */}
      <section className='yoganta-home-first-sec'>
          <div className='yoganta-home-first-sec-text'>
              <h1>Yoganta<span>Live</span></h1>
              <h3>Live Online Yoga Classes</h3>
              <p><strong>Flexible schedules from 6 am to 8 pm, India</strong></p>
              <p>Dedicated therapeutic yoga for diabetes, knee pain, back pain & other chronic ailments.</p>
              {/* Join Us  */}
              {/* <button type='button' className= 'join-us-body-btn' onClick={()=>setModalIsOpen(true)}>JOIN NOW</button>
              <Popup handleClose={()=>setModalIsOpen(false)} show={modalIsOpen}/> */}
               {/* Join Us  */}
               <div>
               <WebsiteLeadForm />
               </div>
               
          </div>
          <div className='yoganta-home-first-sec-img'>
              <img src={yogantaLiveImg} alt="YogantaLive Banner img" />
          </div>
      </section>

      <section className='yoganta-live-benifits'>
        <div className='yoganta-live-benifits-heading'>
          <h2>Benefits of Yoganta Live</h2>
        </div>
        <div className='yoganta-live-benifits-benifit'>
          <div className='yoganta-live-benifits-benifit-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Unlimited Classes</h3></div>
          </div>
          <div className='yoganta-live-benifits-benifit-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Flexible Schedules</h3></div>
          </div>
          <div className='yoganta-live-benifits-benifit-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Dedicated Therapeutic Yoga</h3></div>
          </div>
          <div className='yoganta-live-benifits-benifit-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Free Diet Consultation</h3></div>
          </div>
          <div className='yoganta-live-benifits-benifit-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>Pause and Resume Subscription</h3></div>
          </div>
          <div className='yoganta-live-benifits-benifit-card'>
            <div><CheckCircleIcon /></div>
            <div><h3>No commute - Save Time & Money</h3></div>
          </div>
        </div>
      </section>

      <section className='yoganta-live-one-on-one'>
      <div className='yoganta-live-one-on-one-main'>
        <div className='yoganta-live-one-on-one-text'>
              <h4>Live 1 on 1 Class</h4>
              <p>JOIN OUR PERSONAL TRAINING PROGRAM</p>
              <p>Contact us and let us help you decide what practice fits your lifestyle best</p>
              {/* <p><strong>COMMIT TO BE FIT!</strong></p> */}
        </div>
        <div className='yoganta-live-one-on-one-contacts'>
            <div className='yoganta-live-one-on-one-contact'>
                <PhoneCallbackIcon />
              <h4><a href="tel:+919985955527">+91 99859 55527</a></h4>
            </div>
            <div className='yoganta-live-one-on-one-contact'>
                <MailIcon />
              <h4><a href="mailto:hello@yoganta.in">hello@yoganta.in</a></h4>
            </div>
        </div>
      </div>
      </section>

      <section className="life-yoganta">
        <h2>Yoganta Live Gallery </h2>
        <img src={yogantaLive1} alt="yogantaLive1" />
        {readMore && extraContent}
        <button className="life-yoganta-btn " type="button" onClick={()=>{setReadMore(!readMore)}} data-toggle="collapse" data-target="#moreimages" aria-expanded="false" aria-controls="moreimages">
          <h3>{linkName}</h3>
        </button>
      </section>

      <section className='yoganta-live-get-started'>
        <div className='yoganta-live-get-started-main'>
        <p>GET IN SHAPE! Contact us at<br /><a href="mailto:hello@yoganta.in">hello@yoganta.in</a> or <a href="tel:+919985955527">+91 99859 55527</a></p>
        </div>
        <button className='join-us-body-btn' type='button' onClick={()=>setModalIsOpen(true)}>JOIN NOW</button>
        <Popup handleClose={()=>setModalIsOpen(false)} show={modalIsOpen}/>
      </section>

      

      <PunchLine message="Celebrate your mind and body health"/>
    </div>
  );
}

export default YogantaLive;