import React from 'react';
import './LPFeatured.css'
import EDTimes from "../../../../assets/edtimes-logo.png";
import APNLive from "../../../../assets/apnlive.png";
import DailyHunt from "../../../../assets/dailyhunt_logo.svg";
import RepublicNewsToday from "../../../../assets/republic-news.png";
import EntrepreneurView from "../../../../assets/entrepreneur-view.png";
import AsianNews from "../../../../assets/asian-news.png";
import TheStartupStory from "../../../../assets/The-Startup-Story.jpg";
import StarNewsLine from "../../../../assets/Star-Newsline.png";
import BestNewsJournal from "../../../../assets/Best-News-Journal.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const LPFeatured = () => {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1025 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 469 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 468, min: 0 },
          items: 1
        }
      };

    return(
        <section className='featured'>
                <div className='featured-main-sec'>
                    <h2>Featured in</h2>
                </div>
            <Carousel responsive={responsive} showDots={true}>
            <div className='featured-main-sec'>
                    {/* <a rel="noreferrer" href="https://edtimes.in/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank"> */}
                    <img src={EDTimes} alt='ED Times logo'/>
                    {/* <h4>ED Times</h4> */}
                    {/* </a>  */}
            </div>
            <div className='featured-main-sec'>
                    {/* <a rel="noreferrer" href="https://apnlive.com/press-release/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank"> */}
                    <img src={APNLive} alt='APN Live logo'/>
                    {/* <h4>APN Live</h4> */}
                    {/* </a>  */}
            </div>
            <div className='featured-main-sec'>
                    {/* <a rel="noreferrer" href="https://m.dailyhunt.in/news/india/english/ed+times-epaper-edtimes/yoganta+helps+you+to+be+free+from+pain+and+lead+a+healthy+and+happy+life-newsid-n377775114?s=a&uu=0xa75317646e3f2bc8&ss=wsp" target="_blank"> */}
                    <img src={DailyHunt} alt='dailyhunt logo'/>
                    {/* <h4>dailyhunt</h4> */}
                    {/* </a>  */}
            </div>
            <div className='featured-main-sec'>
                    {/* <a rel="noreferrer" href="https://republicnewstoday.com/index.php/2022/04/16/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank"> */}
                    <img src={RepublicNewsToday} alt='Republic News Today logo'/>
                    {/* <h4>Republic News Today</h4> */}
                    {/* </a>  */}
            </div>
            <div className='featured-main-sec'>
                    {/* <a rel="noreferrer" href="https://entrepreneurview.in/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank"> */}
                    <img src={EntrepreneurView} alt='Entrepreneur View logo'/>
                    {/* <h4>Entrepreneur View</h4> */}
                    {/* </a>  */}
            </div>
            <div className='featured-main-sec'>
                    {/* <a rel="noreferrer" href="https://asiannews.in/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank"> */}
                    <img src={AsianNews} alt='Asian News logo'/>
                    {/* <h4>Asian News</h4> */}
                    {/* </a>  */}
            </div>
            <div className='featured-main-sec'>
                    {/* <a rel="noreferrer" href="https://thestartupstory.co.in/index.php/2022/04/16/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank"> */}
                    <img src={TheStartupStory} alt='The Startup Story logo'/>
                    {/* <h4>The Startup Story</h4> */}
                    {/* </a>  */}
            </div>
            <div className='featured-main-sec'>
                    {/* <a rel="noreferrer" href="https://starnewsline.com/index.php/2022/04/16/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank"> */}
                    <img src={StarNewsLine} alt='Star News Line logo'/>
                    {/* <h4>Star News Line</h4> */}
                    {/* </a>  */}
            </div>
            <div className='featured-main-sec'>
                    {/* <a rel="noreferrer" href="https://bestnewsjournal.com/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life-primex-news-network/" target="_blank"> */}
                    <img src={BestNewsJournal} alt='Best News Journal logo'/>
                    {/* <h4>Best News Journal</h4> */}
                    {/* </a>  */}
            </div>
        
                     
            </Carousel>
                
            </section>
    )
}

export default LPFeatured;