import React from 'react';
import './countdown.css';
import CountDownTimer from './CountDownTimer';

function CountDownPage() {
    const hoursMinSecs = {hours:1, minutes: 0, seconds: 12}
    return (
        <div className="counter">
            <div className="countdown-title">
                <div className="countdown-line">
                    <p className="wellness-sale"><strong>Wellness Flash Sale - Only for today</strong></p>
                    <p className="enroll-now"> ENROLL NOW! </p>
                </div>
            </div>
            <div className="timer">
                <CountDownTimer hoursMinSecs={hoursMinSecs}/>
                <p className="time-format">HH:MM:SS</p> 
            </div>
         </div>
    );
}

export default CountDownPage;