import React from 'react';
import {Helmet} from "react-helmet";
import './ThankYou.css'
import Thankbanner from "../../../assets/thank-you.png";
import YouTubeIcon from '@mui/icons-material/YouTube';
import PinterestIcon from '@mui/icons-material/Pinterest';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import CheckIcon from '@mui/icons-material/Check';
import PunchLine from '../../commonComps/PunchLine';

function ThankYou() {
  return (
    <div className="thank-you">
    {/* Metadata */}
     <Helmet>
        <title>Thank you - Yoganta</title>        
      </Helmet>
    {/* Metadata */}
      <div className="thank-main">
      <div className="thank-main-main">
        <div className="thank-main-texts">
          <h1>Thank You For Being Awesome<br/>
          <CheckIcon color="success" className="check-mark"/>
          </h1>

          <p className="thank-main-texts-paraOne">We have received your request and will contact you shortly.</p><hr />
          <p className="thank-main-texts-paraOne">If you need immediate assistance, please call or email us.</p>
          <p>Phone : <a href="tel:+919985955527">+91 99859 55527</a></p>
          <p>Email : <a href="mailto:hello@yoganta.in">hello@yoganta.in</a></p>
          <hr/>
          <p>Connect With Us</p>
          <div className="thank-main-texts-links">
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://www.facebook.com/TheYoganta/" target="_blank"><FacebookIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://www.instagram.com/theyoganta/" target="_blank"><InstagramIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://twitter.com/TheYoganta" target="_blank"><TwitterIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://in.pinterest.com/theyoganta/" target="_blank"><LinkedInIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://www.linkedin.com/company/theyoganta" target="_blank"><PinterestIcon /></a>
                    <a className="col-1 social_handle_contact" rel="noreferrer noopener" href="https://www.youtube.com/channel/UCdgJhbumN3X6hRBmndCSyaQ" target="_blank"><YouTubeIcon /></a>
          </div>
        </div>
        <div className="thank-main-img">
              <img src={Thankbanner} alt="Thank you banner" />
        </div>
      </div>
      </div>
      <PunchLine message="we are excited to see you"/>
    </div>
  );
}

export default ThankYou;