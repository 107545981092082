import React, { Component } from "react";
import Faq from "react-faq-component";

const general = {
    // title: "Frequently Asked Questions",
    rows: [
        {
            title:
                "Where are you based on?",
            content: 
            `
            We are based in Hyderabad
            `,
        },
        {
            title:
                "When does the trial begin?",
            content: 
            `
            After payment confirmation for the trial pack, our team will contact you within 24 hours with trial details
            `,
        },
        {
            title:
                "How do you conduct online classes?",
            content: 
            `
            We use ZOOM/GoogleMeet video conferencing tools. All sessions are two-way and interactive.
            `,
        },
        {
            title:
                "Do you provide the recording?",
            content: 
            `
            No! We do not provide a recording of the session if you miss any session you are free to participate in any other session.
            `,
        },
        {
            title:
                "Is 1-on-1 yoga also available?",
            content: 
            `
            Yes! Please contact us on +91-99859-55527 and schedule your 1-on-1 private session at your convenient time.
            `,
        },
        {
            title:
                "I think the price is a bit high. Is there any discount available?",
            content: 
            `
            Yes! We offer discounts on 3 months and longer packages. Please contact us at +91-99859-55527 as our team will guide you to find the best package available for you.
            `,
        },
    ],
};

export default class LPAccordian extends Component {
    render() {
        return ( 
                <div>
                    <Faq
                        data={general}
                        styles={{
                            bgColor: "white",
                            rowTitleColor: "black",
                            rowTitleTextSize: "large",
                            rowContentColor: "black",
                            rowContentTextSize: "16px",
                            rowContentPaddingTop: "10px",
                            rowContentPaddingBottom: "10px",
                            rowContentPaddingLeft: "3%",
                            rowContentPaddingRight: "2%",
                            arrowColor: "black",
                            transitionDuration: "0.2s",
                            timingFunc: "linear",
                        }}
                    /> 
                    
                    <br/>
                </div> 
        );
    }
}