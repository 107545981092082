import * as React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Homepage from './components/homepage/Homepage';
import Footer from './components/Footer/Footer';
import NavBar from './components/Navbar/NavBar';

import Shop from "./components/shop/Shop"
import Contact from './components/Contact/Contact';
import About from './components/about/About';
import Careers from './components/careers/Careers';
import Corporate from './components/corporate/Corporate';
import Faq from './components/faq/Faq';
import TermsOfUse from './components/termsOfUse/TermsOfUse';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
import Pricing from './components/pricing/Pricing';
import YogantaLive from './components/yogantaLive/YogantaLive';
import YogantaHome from './components/yogantaHome/yogantaHome';
import YogantaCure from './components/yogantaCure/yogantaCure';

import GroupYogaLP from './components/landingPages/groupYogaLP/GroupYogaLP';
// import GroupYogaPaid from './components/landingPages/groupYogaLP/GroupYogaPaid';

import ThankYou from './components/landingPages/leadForm/ThankYou';


import YogaChallenge from "./components/events/YogaChallenge";
import SuryanamaskarOct2021 from "./components/events/SuryanamaskarOct2021";
import SuryanamaskarNov2021 from "./components/events/SuryanamaskarNov2021";
import SuryanamaskarJan2022 from "./components/events/SuryanamaskarJan2022";

import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import ScrollButton from "./components/ScrollButton/ScrollButton";
import Nutritionist from './components/job-opening/Nutritionist';
import YogaInstructor from './components/job-opening/YogaInstructor';
import CorporateSalesManager from './components/job-opening/CorporateSalesManager';




function App() {

  return (
    <BrowserRouter>
    	<ScrollToTop />
      <NavBar />
      <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="yogantaLive" element={<YogantaLive />} />
          <Route path="yogantaHome" element={<YogantaHome />} />
          <Route path="yogantaCure" element={<YogantaCure />} />
          <Route path="about" element={<About />} />
          <Route path="shop" element={<Shop />} />
          <Route path="contact" element={<Contact />} />
          <Route path="careers" element={<Careers />} />
          <Route path="corporate-wellness" element={<Corporate />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="faq" element={<Faq />} />
          <Route path="terms-and-conditions" element={<TermsOfUse />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />


          <Route path="careers/nutritionist" element={<Nutritionist />} />
					<Route path="careers/yoga-instructor" element={<YogaInstructor />} />
					<Route path="careers/corporate-sales-manager" element={<CorporateSalesManager />} />

          <Route path="live-online-yoga-group-classes-yoganta" element={<GroupYogaLP />} />
          {/* <Route path="yoganta-online-yoga-classes" element={<GroupYogaPaid />} /> */}

          <Route path="thank-you" element={<ThankYou />} />




          <Route path="yoganta-online-yoga-challenge" element={<YogaChallenge />} />
					<Route path="suryanamaskar-challenge-oct21" element={<SuryanamaskarOct2021 />} />
					<Route path="suryanamaskar-challenge-november-2021" element={<SuryanamaskarNov2021 />} />
					<Route path="suryanamaskar-challenge-january-2022" element={<SuryanamaskarJan2022 />} />

          <Route path="*" element={<Navigate to="/" replace />} />
          <Route/>



      </Routes>
      <Footer />
      <ScrollButton />
    </BrowserRouter>
  );
}

export default App;
