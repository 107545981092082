import React from 'react';
import './JobOpening.css';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Helmet} from "react-helmet";

function YogaInstructor() {
    return (
      <div>
      {/* Metadata */}
      <Helmet>
            <title>Careers - Yoganta</title>              
      </Helmet>
      {/* Metadata */}
      <section className="jobopening-top">
      <div>
        <h1 className="page_name">We're Hiring</h1>
      </div>
      </section>
      
      <section id='middle-part'>
          {/* <div className='back-to-openings'>
          <ArrowBackIosIcon />
            <span>Back to all openings</span>
          </div> */}
          <div className='main-content'>
              <h1 className='middle-heading'>Consultant - Yoga Instructor</h1>
              <p>Hyderabad, Remote | Full-Time</p>
              <hr />

              <div className='opening-paras'>
                  <h5>Job Role:</h5>
                  <p>
                  Leads group classes in yoga. They teach students how to do various stretching poses, practice meditation, and promote mindfulness in addition to overall well-being
                  </p>
                  {/* <p>{data.job_role_p_2}</p> */}
              </div>

              <div className='opening-paras'>
                  <h5>Responsibilities:</h5>
                  <ul>
                    <li>
                    Instructing small or large groups of new, intermediate or experienced yoga practitioners
                    </li>
                    <li>
                    Demonstrating stretching poses, including alternative, advanced and adjusted positions to provide more accessibility to students of different levels
                    </li>
                    <li>
                    Sharing the basic spiritual and intellectual principles of yoga
                    </li>
                    <li>
                    Ensure customer satisfaction and assist in answering all participant queries
                    </li>
                    <li>
                    Maintain Yoganta code of conduct
                    </li>
                  
                  </ul>
              </div>

              <div className='opening-paras'>
                  <h5>Skills and Qualifications:</h5>
                  <ul>
                    <li>
                    Must be a Certified Yoga Instructor at least 200 hours RYT
                    </li>
                    <li>
                    Strong communication and interpersonal skills
                    </li>
                    <li>
                    Minimum of 1 years of Yoga teaching experience
                    </li>
                    <li>
                    Flexible to reschedule appointments
                    </li>
                    <li>
                    The most important passion for teaching Yoga
                    </li>
                  </ul>
              </div>

              <div className='after-opening-paras'>
              <p>If you feel like you have the skills and if you feel inspired by our mission, we would love to review your application.</p>
              </div>
          </div>
      </section>

      <section className='last-sec'>
        <h1>To apply, write to us at <a href="mailto:hello@yoganta.in">hello@yoganta.in</a> with your profile</h1>
      </section>
      
      


      </div>
    );
  }
  
  export default YogaInstructor;
