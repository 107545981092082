import React from 'react';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import './SuryanamaskarOct2021.css';

function SuryanamaskarOct2021() {
  return (
    <div className="challenge">
    {/* Metadata */}
     <Helmet>
        <title>Yoganta Suryanamaskar Challenge October 2021</title>
        <meta name="description" content="Yoganta Suryanamaskar Challenge October 2021" />
        <link rel="canonical" href="https://www.yoganta.in/suryanamaskar-challenge-oct21"/>
		    <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
		    <meta name="keywords" content="Yoganta Suryanamaskar Challenge October 2021, yoganta, yoga challenge" />
      
      </Helmet>
    {/* Metadata */}
        <div className="contact-main">
            <iframe title="October 2021" width =	"100%" height = "800px" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRY_jhH2RFdiw7r9qNbniB2UiBu_dNgq_HFhXBsFzfDqzOPNHAJCwwAmfzHZrqDVDl3dJ_ADjllaI3H/pubhtml?widget=true&amp;headers=false"></iframe>
       </div>
    </div>
  );
}

export default SuryanamaskarOct2021;