import React,{useState} from 'react';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import './Careers.css'
import imageone from "../../assets/career-firstsec-img.PNG";
import c1 from "../../assets/c1.webp";
import c2 from "../../assets/c2.webp";
import c3 from "../../assets/c3.webp";
import PunchLine from '../commonComps/PunchLine';
import { NavLink } from 'react-router-dom';


function Careers() {
  const [readMore,setReadMore]=useState(false);

  const extraContent=<div className="life-yoganta" style={{transitionDelay: '.5s'}}>
      <img src={c2} alt="life at yoganta c2" /><br />
      <img src={c3} alt="life at yoganta c3" /><br />
  </div>
  const linkName=readMore?'See less ↑':'See more ↓'



    return (
      <div className='career-main'>
          {/* Metadata */}
            <Helmet>
                <title>Careers - Yoganta</title>
                <meta name="description" content="Become our Holistic Partner. Join the Team! We are hiring talented and dedicated practitioner of holistic wellness from yoga, fitness, Ayurveda, naturopathy, chronic pain management" />
                <link rel="canonical" href="https://www.yoganta.in/careers"/>
                <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
                <meta name="keywords" content="yoganta careers, careers at yoganta, job opening at yoganta Hyderabad, yoga teacher job, nutritionist job, dietition job, fitness trainer job" />
              
              </Helmet>
          {/* Metadata */}
        <section className="career-top">
        <div>
          <h1 className="page_name">Careers</h1>
        </div>
        </section>


        <section className='first-sec'>
          <div className='first-sec-text'>
            <h2>Become our Holistic Partner</h2>
            <p>If you are a practitioner of holistic wellness such as yoga, fitness, Ayurveda, naturopathy, chronic pain management, etc., 
            and believe that you can serve the people to live healthy lifestyles you can explore partnering with Yoganta to flourish in your ongoing realm of work.
            <br />
            <br />Email <a href="mailto:hello@yoganta.in">hello@yoganta.in</a> to become our partner</p>
          </div>
          <div className='first-sec-img'>
          <img src={imageone} alt="contact us banner" />
          </div>
      </section>

      <section className='join-the-team'>
        <div className='join-the-team-heading'>
          <h2>Join the Team!</h2>
        </div>
        
        <div className='join-the-team-boxes'>
          <h3>Services</h3>
          <hr />
          <div className='join-the-team-box'>
            <div>
              <h3>Consultant - Nutritionist</h3>
              <p><strong>Full time, Hyderabad, Remote</strong>
              </p>
            </div>
            <div>
              <button type="button" className="career-btn"> <NavLink to="/careers/nutritionist">APPLY HERE</NavLink></button> 
            </div>
          </div>
          <div className='join-the-team-box'>
            <div>
              <h3>Consultant - Yoga Instructor</h3>
              <p><strong>Full time, Hyderabad, Remote</strong>
              </p>
            </div>
            <div>
            <button type="button" className="career-btn"> <NavLink to="/careers/yoga-instructor">APPLY HERE</NavLink></button> 
            </div>
          </div>
        </div>

        <div className='join-the-team-boxes'>
          <h3>Sales and Marketing</h3>
          <hr />
          <div className='join-the-team-box'>
            <div>
              <h3>Corporate Sales Manager</h3>
              <p> <strong>Full time, Hyderabad, Bengaluru, New Delhi</strong>
              </p>
            </div>
            <div>
            <button type="button" className="career-btn"> <NavLink to="/careers/corporate-sales-manager">APPLY HERE</NavLink></button> 
            </div>
          </div>
          {/* <div className='join-the-team-box'>
            <div>
              <h3>Product Designer</h3>
              <p>Full time, Remote
              <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
            </div>
            <div>
            <Link to={{
              pathname: "/careers/product-designer",
              data: {
                tag:"Product Designer",
                location:"Locaton",
                time:"Full-Time",
                job_role_p_1:"Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit ",
                job_role_p_2:"Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit ",
                responsibilities_p_1:"Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit ",
                responsibilities_p_2:"Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit ",
                requirements_p_1:"Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit ",
                requirements_p_2:"Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit ",
              } // your data array of objects
            }}>
              <button type="button">KNOW MORE AND APPLY </button>
              </Link>
            </div>
          </div> */}
        </div>
      </section>

      <section className='why-yoganta'>
        <div>
          <h2 className='why-yoganta-heading'>Why Yoganta?</h2>
        </div>
        <div className='why-yoganta-paras'>
          <div>
            <h3>Flexible working and work-life balance</h3>
            <p>Flexible working opportunities that can benefit everyone: employers, employees and their families.</p>
          </div>
          <div>
            <h3>A diverse and inclusive community</h3>
            <p>We're committed to building a diverse and inclusive environment where you feel you belong.</p>
          </div>
          <div>
            <h3>One team One dream</h3>
            <p>We always put the whole company first. Everyone has a voice, we listen to each other's opinions.</p>
          </div>
        </div>
      </section>


      <section className="life-yoganta">
        <h2>Life at Yoganta</h2>
        <img src={c1} alt="life at yoganta c1" /><br />
        {readMore && extraContent}
        <button className="life-yoganta-btn" type="button" onClick={()=>{setReadMore(!readMore)}} data-toggle="collapse" data-target="#moreimages" aria-expanded="false" aria-controls="moreimages">
          <h3>{linkName}</h3>
        </button>

        {/* <a className="read-more-link" onClick={()=>{setReadMore(!readMore)}}><h2>{linkName}</h2></a>
      {readMore && extraContent} */}
      
        {/* <div class="collapse" id="moreimages">
          <div class="card card-body">
          <img src={imageone} alt="cannot load image" />
          </div>
        </div> */}
      </section>
      
      <PunchLine message="Embark on the most exciting phase of your career with us"/>

      </div> 
    );
  }
  

  // var btn = document.getElementsByClassName("show-more-less");
  // var cardBody = document.getElementsByClassName("card-body");
        
  // btn.addEventListener('click', (e)=> {
  //   cardBody.classList.toggle('show-more');
  //   if (btn.innerText === 'Show More') {
  //     btn.innerText = 'Show Less';
  //   }else {
  //     btn.innerText = 'Show More';
  //   }
  // })


  export default Careers;