import React from 'react';
import './JobOpening.css';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Helmet} from "react-helmet";

function Nutritionist() {
    return (
      <div>
      {/* Metadata */}
      <Helmet>
            <title>Careers - Yoganta</title>              
      </Helmet>
      {/* Metadata */}
      <section className="jobopening-top">
      <div>
        <h1 className="page_name">We're Hiring</h1>
      </div>
      </section>
      
      <section id='middle-part'>
          {/* <div className='back-to-openings'>
          <ArrowBackIosIcon />
            <span>Back to all openings</span>
          </div> */}
          <div className='main-content'>
              <h1 className='middle-heading'>Consultant Nutritionist</h1>
              <p>Hyderabad, Remote | Full-Time</p>
              <hr />

              <div className='opening-paras'>
                  <h5>Job Role:</h5>
                  <p>
                    A licensed nutritionist to provide our clients with comprehensive advice on matters of well-being and responsible for assessing people's needs using scientific methods and determining the most suitable nutrition plans.
                  </p>
                  {/* <p>{data.job_role_p_2}</p> */}
              </div>

              <div className='opening-paras'>
                  <h5>Responsibilities:</h5>
                  <ul>
                    <li>
                    Create meal plans for clients who want to lose weight, add muscle or achieve other nutrition-related goals
                    </li>
                    <li>
                    Consult with clients on healthy nutrition and dietary choices
                    </li>
                    <li>
                    Author written meal plans for dissemination to members
                    </li>
                    <li>
                    Manage the consultation calendar for both yourself and our personal trainers
                    </li>
                    <li>
                    Publish one blog post monthly on nutritional habits and practices for our website
                    </li>
                  
                  </ul>
              </div>

              <div className='opening-paras'>
                  <h5>Skills and Qualifications:</h5>
                  <ul>
                    <li>
                    Minimum Bachelor's Degree in Nutrition or Dietetics preferred
                    </li>
                    <li>
                    1+ years experience as a professional nutritionist
                    </li>
                    <li>
                    Must be a registered dietitian (RD)
                    </li>
                    <li>
                    Excellent verbal and presentation skills
                    </li>
                    <li>
                    Good understanding of research methods and data analysis
                    </li>
                  </ul>
              </div>

              <div className='after-opening-paras'>
              <p>If you feel like you have the skills and if you feel inspired by our mission, we would love to review your application.</p>
              </div>
          </div>
      </section>

      <section className='last-sec'>
        <h1>To apply, write to us at <a href="mailto:hello@yoganta.in">hello@yoganta.in</a> with your profile</h1>
      </section>
      
      


      </div>
    );
  }
  
  export default Nutritionist;
