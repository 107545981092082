import React from 'react';
import './GroupYogaLP.css';
// import yogantaLiveImg from "../../../assets/yogantaLive-banner.jpeg";
import KaavyaYogantaLive from "../../../assets/Kaavya-yogantaLive-banner.jpeg";
import LPSlots from '../LPComps/LPSlots/LPSlots';
import LPTestimonial from '../LPComps/LPTestimonials/LPTestimonial';
import LPFeatured from '../LPComps/LPFeatured/LPFeatured';
import LPTrainers from '../LPComps/LPTrainers/LPTrainers';
import LPFAQ from '../LPComps/LPFAQ/LPFAQ';
import PunchLine from '../../commonComps/PunchLine';
// import LPCTA from '../LPComps/LPCTA/LPCTA';
import LPPricing from '../LPComps/LPPricing/LPPricing';
import LPContact from '../LPComps/LPContact/LPContact';
import CountDownPage from '../../countdown/CountDownPage';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import LeadForm from '../leadForm/LeadForm';

const GroupYogaLP = () => {
    return(
        <div>
        {/* Metadata */}
				<Helmet>
					<title>Online Yoga Classes - Yoganta</title>
					<meta name="description" content="YogantaLive - Practice Yoga Anytime You Choose!, Online Interactive Yoga Classes, Flexible schedules from 6 am to 8 pm IST, Live 1 on 1 Class, Dedicated therapeutic yoga" />
     			</Helmet>
    		{/* Metadata */}
            <div className='group-yoga-lp-first-sec'>
                <div className='group-yoga-lp-first-sec-text'>
                    <h1>Yoganta<span>Live</span></h1>
                    <h3>4.9&#11088; Rated | Online Yoga Classes</h3>
                    <p><strong>Flexible schedules from 6 am to 8 pm, India</strong></p>
                    <p>Dedicated therapeutic yoga for diabetes, knee pain, back pain & other chronic ailments.</p> 
                </div>
            </div>
            <LeadForm/>
            <img src={KaavyaYogantaLive} className='lpimg' alt="YogantaLive Banner" />
            <LPTrainers />
            {/* <LPCTA /> */}
            <LeadForm/>
            <LPSlots />
            <CountDownPage/>
            <LPPricing/>
            <LPTestimonial/>
            <LPFeatured/>
            <LPFAQ />
            <LeadForm/>
            <LPContact />
            {/* <LPCTA /> */}
            <PunchLine message="Transform your body and mind"/>
            
        </div>
    )
}

export default GroupYogaLP;