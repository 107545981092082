import React from 'react';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';

function SuryanamaskarNov2021() {
  return (
    <div className="challenge">
    {/* Metadata */}
     <Helmet>
        <title>Yoganta Suryanamaskar Challenge November 2021</title>
        <meta name="description" content="Yoganta Suryanamaskar Challenge November 2021" />
        <link rel="canonical" href="https://www.yoganta.in/suryanamaskar-challenge-november-2021"/>
		<meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
		<meta name="keywords" content="Yoganta Suryanamaskar Challenge November 2021, yoganta, yoga challenge, free yoga classes," />
      
      </Helmet>
    {/* Metadata */}
        <div className="contact-main">
            <iframe title="November 2021" width =	"100%" height = "800px" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQf3D9JYOR6qSrKS1X1-03kVGgypZUGId_LaqUvCLrD3I2dZEWAYZQ6JBmNvMWBxHp-wbFW-RqCUfAe/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
       </div>
    </div>
  );
}

export default SuryanamaskarNov2021;