import React from 'react';
import './LPPricing.css';

import { AiOutlineCheckCircle } from 'react-icons/ai';
import { RiCheckboxBlankCircleLine } from 'react-icons/ri';
import LeadForm from '../../leadForm/LeadForm';
// import LPCTA from '../LPCTA/LPCTA';

// const newWindow = () => {
//     return window.open(`https://rzp.io/l/yoganta-live-demo`,"_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=350,width=900,height=600")
    
// }

const LPPricing = () => {
    return(
        <div className='lppricing'>
            <div className='lppricing-heading'>
                <h1>YOGANTA LIVE</h1>
                <p>Benefits</p>
                <div className='lppricing-heading-benifit'>
                    <AiOutlineCheckCircle size={20} />
                    <p>Unlimited Classes</p>
                </div>
                <div className='lppricing-heading-benifit'>
                    <AiOutlineCheckCircle size={20} />
                    <p>Flexible Schedules</p>
                </div>
                <div className='lppricing-heading-benifit'>
                    <AiOutlineCheckCircle size={20} />
                    <p>Pause Membership</p>
                </div>
                <div className='lppricing-heading-benifit'>
                    <AiOutlineCheckCircle size={20} />
                    <p>Free Diet Consultation</p>
                </div>
                <hr />
            </div>
            <div className='choose-sub'>
                <p>Subscription plans</p>
                <div className='choose-sub-options'>
                    <div className='choose-sub-options-left'>
                        <RiCheckboxBlankCircleLine size={20} />
                        <p>12 Months</p>
                    </div>
                    <div className='choose-sub-options-right'>
                        <p className='choose-sub-options-right-line-through'>₹ 26400</p>
                        <p>₹ 8895</p>
                    </div>
                </div>
                <hr />
                <div className='choose-sub-options'>
                    <div className='choose-sub-options-left'>
                        <RiCheckboxBlankCircleLine size={20} />
                        <p>6 Months</p>
                    </div>
                    <div className='choose-sub-options-right'>
                        <p className='choose-sub-options-right-line-through'>₹ 13200</p>
                        <p>₹ 5845</p>
                    </div>
                </div>
                <hr />
                <div className='choose-sub-options'>
                    <div className='choose-sub-options-left'>
                        <RiCheckboxBlankCircleLine size={20} />
                        <p>3 Months</p>
                    </div>
                    <div className='choose-sub-options-right'>
                        <p className='choose-sub-options-right-line-through'>₹ 6600</p>
                        <p>₹ 3795</p>
                    </div>
                </div>
                <hr />
                <div className='choose-sub-options'>
                    <div className='choose-sub-options-left'>
                        <RiCheckboxBlankCircleLine size={20} />
                        <p>1 Month</p>
                    </div>
                    <div className='choose-sub-options-right'>
                        <p className='choose-sub-options-right-line-through'>₹ 2200</p>
                        <p>₹ 1395</p>
                    </div>
                </div>
                <hr />
                <div className='choose-sub-options choose-sub-options-highlited'>
                    <div className='choose-sub-options-left choose-sub-options-left-highlited'>
                        <AiOutlineCheckCircle size={20} />
                        <p>1-Day Trial</p>
                    </div>
                    <div className='choose-sub-options-right choose-sub-options-right-highlited'>
                        {/* <p className='choose-sub-options-right-line-through'>₹ 9000</p> */}
                        {/* <p>₹ 95</p> */}
                        <p>FREE</p>
                    </div>
                </div>
                <hr />
                {/* <button type='button' className='choose-sub-btn' onClick={() => newWindow()}>Schedule Demo Now</button> */}
                <LeadForm/>
            </div>
        </div>
    )
}

export default LPPricing