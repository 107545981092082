import React from 'react'
import './Common.css'
const PunchLine = ({ message }) => {
  return (
    <div className="punchline_background">
      <div className="container">
        <p className="m-4">{message}</p>
      </div>
    </div>
  )
}

export default PunchLine;