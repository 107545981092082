import React from 'react';
import './LPFAQ.css';
import Accordian from "./LPAccordian";

function LPFAQ () {
    return (
      <div>
        <div className="lpfaq-heading">
            <h2>Frequently Asked Questions</h2>
        </div>
        <div className="lpaccordian">
          <Accordian/>
        </div>
      </div>
    );
  }
  
  export default LPFAQ;