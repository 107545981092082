import React,{useState} from 'react';
import HomepageImg from '../../assets/homepage-pp.webp';
import YogantaLiveImg from '../../assets/YogantaLive-pp.webp';
import Offering from '../../assets/offering.png';
import yogantaCure from '../../assets/yoganta-cure.png';
// import googlePlay from '../../assets/play-store.svg';
// import appStore from '../../assets/app-store.svg';
// import YogantaApp from '../../assets/Yoganta-app.png'
// import maleAvatar from '../../assets/male-avatar.png';
import femaleAvatarImg from "../../assets/female-2.png";
import femaleAvatarImg1 from "../../assets/female-1.png";
// import maleAvatarImg1 from "../../assets/male-1.png";
import maleAvatarImg2 from "../../assets/male-2.png";
import EDTimes from "../../assets/edtimes-logo.png";
import APNLive from "../../assets/apnlive.png";
import DailyHunt from "../../assets/dailyhunt_logo.svg";
import RepublicNewsToday from "../../assets/republic-news.png";
import EntrepreneurView from "../../assets/entrepreneur-view.png";
import AsianNews from "../../assets/asian-news.png";
import TheStartupStory from "../../assets/The-Startup-Story.jpg";
import StarNewsLine from "../../assets/Star-Newsline.png";
import BestNewsJournal from "../../assets/Best-News-Journal.png";

import './Homepage.css';
import PunchLine from '../commonComps/PunchLine';
import { NavLink, Link } from 'react-router-dom';
import PopupStatic from '../../components/pricing/Popup/PopupStatic';
import CountDownPage from '../countdown/CountDownPage';
import Popup from '../pricing/Popup/Popup';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../events/YogaChallenge.css';
import WebsiteLeadForm from '../../../src/components/landingPages/leadForm/WebsiteLeadForm';

function Homepage() {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        }
      };
    const [modalIsOpen, setModalIsOpen] = useState(false);
    return (
        <div className='try'>              
            <section className='homepage-new-top'>
                <div className='homepage-new-top-main'>
                    <div className='homepage-new-top-main-text'>
                        <p className='homepage-new-top-main-text-title'>Take Care of Your Well-Being</p>
                        <p className="homepage-new-top-main-text-sub-title">Start a healthy lifestyle - your only solution to living well.</p>
                        <h1 className='homepage-new-top-main-text-para'>Online Yoga - Nutrition - Chronic Pain Management</h1>
                        <br />
                        {/*### Join Us Button  */}
                        {/* <button type='button' className='join-us-body-btn' onClick={()=>setModalIsOpen(true)}>Join Us</button>  
                        <Popup handleClose={()=>setModalIsOpen(false)} show={modalIsOpen}/> */}
                        {/*### Join Us Button  */}
                        <div>
                        <WebsiteLeadForm/>
                        </div>
                        <br/><button type='button' className='buttonYC'> <NavLink to="/yoganta-online-yoga-challenge">Yoga Challenge</NavLink> </button>  
                    </div>
                    <div className='homepage-new-top-main-img'>
                        <img src={HomepageImg} alt='Homepage PP' />
                    </div>
                </div>
            </section>

            <section className='homepage-new-second-sec'>
                <div className='homepage-new-second-sec-main'>
                    <div className='homepage-new-second-sec-main-head'>
                        <h2>Yoganta Offering</h2>
                        <p>Hello! Nice to meet you. From us, you can enjoy the following</p>
                    </div>
                    <div className='homepage-new-second-sec-main-cards'>
                        <div className='homepage-new-second-sec-main-cards-card'>
                        <Link to="/yogantalive">
                            <img src={YogantaLiveImg} alt='YogantaLive PP' />
                            <div className='homepage-new-second-sec-main-cards-card-text'>
                                <h3>Yoganta<span>Live</span></h3>
                                <h4>Live Online Yoga </h4>
                                <p>Practice yoga anytime you choose!</p>
                                <p><NavLink to='/yogantaLive' className='homepage-new-second-sec-main-cards-card-text-link'>Know More</NavLink></p>
                            </div>
                            </Link>
                        </div>
                        <div className='homepage-new-second-sec-main-cards-card'>
                        <Link to="/yogantahome">
                            <img src={Offering} alt='yogantaHome' />
                            <div className='homepage-new-second-sec-main-cards-card-text'>
                                <h3>Yoganta<span>Home</span></h3>
                                <h4>Home Yoga Classes</h4>
                                <p>Learn yoga from the best yoga teacher at your home</p>
                                <p><NavLink to='/yogantaHome' className='homepage-new-second-sec-main-cards-card-text-link'>Know More</NavLink></p>
                            </div>
                            </Link>
                        </div>
                        <div className='homepage-new-second-sec-main-cards-card'>
                        <Link to="/yogantacure">
                            <img src={yogantaCure} alt='yogantaCure' />
                            <div className='homepage-new-second-sec-main-cards-card-text'>
                                <h3>Yoganta<span>Cure</span></h3>
                                <h4>Holistic Healing </h4>
                                <p>manage lifestyle disorders and lead a normal lifestyle</p>
                                <p><NavLink to='/yogantaCure' className='homepage-new-second-sec-main-cards-card-text-link'>Know More</NavLink></p>
                            </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
         
            <section className='homepage-new-third-sec'>
                <div className='homepage-new-third-sec-main'>
                    <div className='homepage-new-third-sec-main-head'>
                        {/* <h2>Yoganta Fit Library</h2> */}
                        <h2>Yoganta Mind Library</h2>
                        <p>Yoga is the only exercise from which you gain energy instead of burning energy.</p>
                    </div>
                    <div className='homepage-new-third-sec-main-videos'>
                        
                        <div className='homepage-new-third-sec-main-videos-vid'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/FRe_DjY3s9s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Flute Meditation Sounds</p>
                        </div>
                        <div className='homepage-new-third-sec-main-videos-vid'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/SACf4JprbHk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Om Meditation</p>
                        </div>
                        <div className='homepage-new-third-sec-main-videos-vid'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/OylJQYwBBdk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Dawn Meditation</p>
                        </div>
                        <div className='homepage-new-third-sec-main-videos-vid'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/FRe_DjY3s9s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Flute Meditation Sounds</p>
                        </div>
                        <div className='homepage-new-third-sec-main-videos-vid'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/SACf4JprbHk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Om Meditation</p>
                        </div>
                        <div className='homepage-new-third-sec-main-videos-vid'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/OylJQYwBBdk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Dawn Meditation</p>
                        </div>
                        
                    </div></div></section>
                    <section className="carousel1">
                        <Carousel  responsive={responsive} >
                        <div className="card">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/FRe_DjY3s9s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Flute Meditation Sounds</p>
                        </div>
                        <div className="card">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/SACf4JprbHk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Om Meditation</p>
                        </div>
                        <div className="card">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/OylJQYwBBdk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Dawn Meditation</p>
                        </div>
                        <div className="card">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/FRe_DjY3s9s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Flute Meditation Sounds</p>
                        </div>
                        <div className="card">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/SACf4JprbHk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Om Meditation</p>
                        </div>
                        <div className="card">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/OylJQYwBBdk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Dawn Meditation</p>
                        </div>
            </Carousel></section>
                              
                    <div className="button1">
                        <button type='button' className='start-today-btn' onClick={()=>setModalIsOpen(true)}>Start Today</button>
                        <Popup handleClose={()=>setModalIsOpen(false)} show={modalIsOpen}/>
                    </div> 
                 
                 


            {/* <section className='homepage-new-fourth-sec'>
                <div className='homepage-new-fourth-sec-main'>
                    <div className='homepage-new-fourth-sec-main-head'>
                        <h2>Yoganta Mind Library</h2>
                        <p>Meditation takes you away from the social conditioning in the arms of infinite security of knowing yourself.</p>
                    </div>
                    <div className='homepage-new-fourth-sec-main-videos'>
                    <div className='homepage-new-third-sec-main-videos-vid'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/FRe_DjY3s9s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Flute Meditation Sounds</p>
                        </div>
                        <div className='homepage-new-third-sec-main-videos-vid'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/SACf4JprbHk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Om Meditation</p>
                        </div>
                        <div className='homepage-new-third-sec-main-videos-vid'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/OylJQYwBBdk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Dawn Meditation</p>
                        </div>
                        <div className='homepage-new-third-sec-main-videos-vid'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/FRe_DjY3s9s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Flute Meditation Sounds</p>
                        </div>
                        <div className='homepage-new-third-sec-main-videos-vid'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/SACf4JprbHk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Om Meditation</p>
                        </div>
                        <div className='homepage-new-third-sec-main-videos-vid'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/OylJQYwBBdk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Dawn Meditation</p>
                        </div>
                    </div>
                    </div>
                    </section>
                    <section className="carousel2">
                        <Carousel responsive={responsive}  >
                        <div class="card1">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/FRe_DjY3s9s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Flute Meditation Sounds</p>
                        </div>
                        <div className="card1">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/SACf4JprbHk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Om Meditation</p>
                        </div>
                        <div className="card1">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/OylJQYwBBdk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Dawn Meditation</p>
                        </div>
                        <div className="card1">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/FRe_DjY3s9s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Flute Meditation Sounds</p>
                        </div>
                        <div className="card1">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/SACf4JprbHk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Om Meditation</p>
                        </div>
                        <div className="card1">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/OylJQYwBBdk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
                            <p>Dawn Meditation</p>
                        </div>
            </Carousel></section>
                    <div className="button2">
                        <button type='button' className='start-today-btn' onClick={()=>setModalIsOpen(true)}>Start Today</button>
                        <Popup handleClose={()=>setModalIsOpen(false)} show={modalIsOpen}/>
                    </div> */}
            <section className=''>
                <div className=''>
                    <CountDownPage/>
                </div>
            </section>

            <section className='homepage-new-fifth-sec'>
                <div className='homepage-new-fifth-sec-main'>
                    <PopupStatic/>
                </div>
            </section>
            {/* <section className='homepage-new-sixth-sec'>
                <div className='homepage-new-sixth-sec-main'>
                    <div className='homepage-new-sixth-sec-main-texts'>
                        <h3>Get the Yoganta App for your mobile device</h3>
                        <p>Be a part of the Yoganta community</p><br />
                        <div className='homepage-new-sixth-sec-main-texts-links'>
                           <img src={appStore} alt='app-store-logo'></img>
                           <img src={googlePlay} alt='google-play-logo'></img>
                        </div>
                    </div>
                    <div className='homepage-new-sixth-sec-main-img'>
                        <img src={YogantaApp} alt='App download img'/>
                    </div>
                </div>
            </section> */}

            <section className='testimonials'>
                <div className='homepage-new-seventh-sec-main'>
                    <h2>Testimonials</h2>
                </div>
            <Carousel responsive={responsive} showDots={true}>
            <div className='homepage-new-seventh-sec-main'>
                    <p>After starting yoga, I am completely changed! I feel like all aspects of my life have changed. My relationships, my family, my health, everyone has felt a positive change.</p>
                    <img src={femaleAvatarImg} alt='female-2 .png'/>
                    <h5>Anahita Virani</h5>
                    <p>Teacher</p>                    
                </div>
                <div className='homepage-new-seventh-sec-main'>
                    <p>The best yoga class I've ever joined and I wish I found it sooner! It  has helped me find immense clarity in my life and empowered me to be a better person. Everyone needs a Yoganta!</p>
                    <img src={femaleAvatarImg1} alt='female-1 .png'/>
                    <h5>Uditi Rachna</h5>
                    <p>Interior Designer</p>
                </div>
                <div className='homepage-new-seventh-sec-main'>
                    <p>Yoganta has been been a game changer for me! The course has taught me life skills that are making me healthier, happier and more productive than ever before. For those of you who want to start yoga, I highly recommend
                        joining Yoganta.</p>
                    <img src={maleAvatarImg2} alt='male-2 .png'/>
                    <h5>Aakar Murli</h5>
                    <p>Business man</p>
                </div>
                <div className='homepage-new-seventh-sec-main'>
                    <p>I can honestly say it was the best decision I've ever made for my health. I love doing yoga. The trainers are very experienced, kind, and helping me live a healthy lifestyle.</p>
                    <img src={femaleAvatarImg} alt='female-2 .png'/>
                    <h5>Shubhangi Prasai</h5>
                    <p>Housewife</p>
                </div>
                     
            </Carousel>
                
            </section>

            <section className='featured'>
                <div className='featured-main-sec'>
                    <h2>Featured in</h2>
                </div>
            <Carousel responsive={responsive} showDots={true}>
            <div className='featured-main-sec'>
                    <a rel="noreferrer" href="https://edtimes.in/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank">
                    <img src={EDTimes} alt='ED Times logo'/>
                    {/* <h4>ED Times</h4> */}
                    </a> 
            </div>
            <div className='featured-main-sec'>
                    <a rel="noreferrer" href="https://apnlive.com/press-release/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank">
                    <img src={APNLive} alt='APN Live logo'/>
                    {/* <h4>APN Live</h4> */}
                    </a> 
            </div>
            <div className='featured-main-sec'>
                    <a rel="noreferrer" href="https://m.dailyhunt.in/news/india/english/ed+times-epaper-edtimes/yoganta+helps+you+to+be+free+from+pain+and+lead+a+healthy+and+happy+life-newsid-n377775114?s=a&uu=0xa75317646e3f2bc8&ss=wsp" target="_blank">
                    <img src={DailyHunt} alt='dailyhunt logo'/>
                    {/* <h4>dailyhunt</h4> */}
                    </a> 
            </div>
            <div className='featured-main-sec'>
                    <a rel="noreferrer" href="https://republicnewstoday.com/index.php/2022/04/16/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank">
                    <img src={RepublicNewsToday} alt='Republic News Today logo'/>
                    {/* <h4>Republic News Today</h4> */}
                    </a> 
            </div>
            <div className='featured-main-sec'>
                    <a rel="noreferrer" href="https://entrepreneurview.in/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank">
                    <img src={EntrepreneurView} alt='Entrepreneur View logo'/>
                    {/* <h4>Entrepreneur View</h4> */}
                    </a> 
            </div>
            <div className='featured-main-sec'>
                    <a rel="noreferrer" href="https://asiannews.in/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank">
                    <img src={AsianNews} alt='Asian News logo'/>
                    {/* <h4>Asian News</h4> */}
                    </a> 
            </div>
            <div className='featured-main-sec'>
                    <a rel="noreferrer" href="https://thestartupstory.co.in/index.php/2022/04/16/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank">
                    <img src={TheStartupStory} alt='The Startup Story logo'/>
                    {/* <h4>The Startup Story</h4> */}
                    </a> 
            </div>
            <div className='featured-main-sec'>
                    <a rel="noreferrer" href="https://starnewsline.com/index.php/2022/04/16/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life/" target="_blank">
                    <img src={StarNewsLine} alt='Star News Line logo'/>
                    {/* <h4>Star News Line</h4> */}
                    </a> 
            </div>
            <div className='featured-main-sec'>
                    <a rel="noreferrer" href="https://bestnewsjournal.com/yoganta-helps-you-to-be-free-from-pain-and-lead-a-healthy-and-happy-life-primex-news-network/" target="_blank">
                    <img src={BestNewsJournal} alt='Best News Journal logo'/>
                    {/* <h4>Best News Journal</h4> */}
                    </a> 
            </div>
        
                     
            </Carousel>
                
            </section>

            <section className='homepage-new-eighth-sec'>
                <div className='homepage-new-eighth-sec-main'>
                    <div className='homepage-new-eighth-sec-main-div'>
                        {/* <h3 className='homepage-new-eighth-sec-main-div-title'>Work with us</h3> */}
                        <p className='homepage-new-eighth-sec-main-div-para'>
                        Sign Up now and get 1 Month free extension on 3 months class package!
                        </p>
                        {/* <p className="homepage-cta-msg"> Got any questions? Call <strong>+91-99859 55527</strong></p> */}
                        <p className="homepage-cta-msg"> Got any questions? Call <a href="tel:+919985955527">+91 99859 55527</a></p>
                    </div>
                    <div className='homepage-new-eighth-sec-main-div'>
                        <button type='button' className='join-us-body-btn' onClick={()=>setModalIsOpen(true)}>Start Today</button>
                        <Popup handleClose={()=>setModalIsOpen(false)} show={modalIsOpen}/>
                    </div>
                </div>
            </section>

            <PunchLine message="TRANSFORM YOUR BODY AND MIND"/>
        </div>
    );
}

export default Homepage;