import React,{useState} from 'react';
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import './Corporate.css';
import corporateYogaImg from "../../assets/corporate-yoga-banner.jpeg";
import corporateWellness1 from "../../assets/corporate-wellness-collage-1.webp";
import corporateWellness2 from "../../assets/corporate-wellness-collage-2.webp";
import corporateWellness3 from "../../assets/corporate-wellness-collage-3.webp";
import yogantaCorporate1 from "../../assets/yoganta-corporate1-min.png";
import yogantaCorporate2 from "../../assets/yoganta-corporate2-min.png";
import yogantaCorporate3 from "../../assets/yoganta-corporate3-min.png";
import maleAvatar from "../../assets/male-avatar.png";
import femaleAvatar from "../../assets/female-avatar.png";
import clienteleImg from "../../assets/clientele-pic.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PunchLine from '../commonComps/PunchLine';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Corporate() {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        }
      }; 

    const [readMore,setReadMore]=useState(false);

  const extraContent=<div className="life-yoganta">
      <img src={yogantaCorporate2} alt="orporate yoga collage 2 at IBS Hyderabad by Yoganta" />
      <img src={yogantaCorporate3} alt="corporate yoga collage 3 at IBS Hyderabad by Yoganta" />
      <img src={corporateWellness1} alt="corporate wellness banner 1" />
      <img src={corporateWellness2} alt="corporate Wellness2 banner 2" />
      <img src={corporateWellness3} alt="corporate Wellness2 banner 3" />

  </div>
  const linkName=readMore?'See less ↑':'See more ↓'


    return (
        <div className='corporate-main'>
           {/* Metadata */}
                <Helmet>
                    <title>Corporate Wellness Programs - Yoganta</title>
                    <meta name="description" content="Think your employees need some inspiration? Give them the gift of energy and rejuvenation" />
                    <link rel="canonical" href="https://www.yoganta.in/corporate-wellness"/>
                    <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
                    <meta name="keywords" content="yoganta corporate yoga, corporate yoga in Hyderabad, yoga for workplace, yoga for corporate employees, corporate wellness programs" />
                
                </Helmet>
            {/* Metadata */}
            <section className='corporate-first-sec'>
                <div className='corporate-first-sec-text'>
                    <h2>Think your employees need some inspiration?</h2><br />
                    <p><strong>Give them the gift of energy and rejuvenation. </strong><br/> Our corporate yoga classes are designed to introduce employees to the fundamentals of yoga in a fun and active way.</p>
                </div>
                <div className='corporate-first-sec-img'>
                    <img src={corporateYogaImg} alt="corporate yoga banner img" />
                </div>
            </section>

            <section className='corporate-second-sec'>
                <div className='corporate-second-sec-main'>
                    <h1>Corporate Wellness Programs</h1>
                    <p>From us, you and your employees can enjoy the following:</p>
                </div>
                <div className='cards'>
                    <div className='card-main'>
                        <div className='card-logo'>
                            <CheckCircleIcon />
                        </div>
                        <div className='card-texts'>
                            <h3>Pranayama</h3>
                            {/* <p>Pranayama is the practice of controlling energy through the use breath. Implementing breath work in yoga poses allows for more opening and presence.</p> */}
                        </div>
                    </div>
                    <div className='card-main'>
                        <div className='card-logo'>
                            <CheckCircleIcon />
                        </div>
                        <div className='card-texts'>
                            <h3>Meditation</h3>
                            {/* <p>Meditation can be defined as a set of techniques that are intended to encourage a heightened state of awareness and focused attention.</p> */}
                        </div>
                    </div>
                    <div className='card-main'>
                        <div className='card-logo'>
                            <CheckCircleIcon />
                        </div>
                        <div className='card-texts'>
                            <h3>Talk on Lifestyle</h3>
                            {/* <p>Yoga is an integral part of our lifestyle. It removes the impurities from the level of mind and unites everything with the spirit.</p> */}
                        </div>
                    </div>
                    <div className='card-main'>
                        <div className='card-logo'>
                            <CheckCircleIcon />
                        </div>
                        <div className='card-texts'>
                            <h3>Chair Yoga</h3>
                            {/* <p>Chair yoga is a relaxing and gentle form of yoga which explores traditional yoga poses from a seated position.</p> */}
                        </div>
                    </div>
                    <div className='card-main'>
                        <div className='card-logo'>
                            <CheckCircleIcon />
                        </div>
                        <div className='card-texts'>
                            <h3>Laughter Yoga</h3>
                            {/* <p>Laughing yoga, also known as laughter yoga, involves a series of movement and breathing exercises to promote deliberate laughter.</p> */}
                        </div>
                    </div>
                    <div className='card-main'>
                        <div className='card-logo'>
                            <CheckCircleIcon />
                        </div>
                        <div className='card-texts'>
                            <h3>Traditional Yoga</h3>
                            {/* <p>Traditional yoga systems include breathing exercises and asanas or postures, chants, and meditation that can reduce stress and improve immunity and lung functions</p> */}
                        </div>
                    </div>
                </div>
            </section>

                <section className='clientele-padd'>
                <h2>Clientele</h2>
                <Carousel responsive={responsive} showDots={true}>
            
                    <div className='clientele-main-img'>
                        <img src={clienteleImg} alt="corporate brand logos" />
                    </div>
                    
                    <div className='clientele-main-img'>
                        <img src={clienteleImg} alt="corporate brand logos" />
                    </div>
                
                </Carousel>
                </section>
               
               <section className='testimonials'>
               <div className='homepage-new-seventh-sec-main'>
                    <h2>Testimonial</h2>
                </div>
            <Carousel responsive={responsive} showDots={true}>
            <div className='homepage-new-seventh-sec-main'>
                    <p>
                        Some great feedback on the yoga sessions from my manager today! The trainer was a great facilitator and able to deal with our staff well.
                         Everyone was happy and relaxed after the yoga classes so well done! Thanks!
                    </p>
                    <img src={maleAvatar} alt='maleAvatar'/>
                    <h5>Sudeep Sadanand</h5>
                    <p>HR Manager</p>
                </div>
                <div className='homepage-new-seventh-sec-main'>
                    <p>
                        The classes were a fantastic stress release after a busy day in the office and a great way to get to know colleagues that I don't usually get to work with day-to-day. Thanks!
                    </p>
                    <img src={maleAvatar} alt='maleAvatar'/>
                    <h5>Ranajit Kambhampat</h5>
                    <p>HR Manager</p>
                </div>
                <div className='homepage-new-seventh-sec-main'>
                    <p>
                        I had never participated in a yoga class until my work had arranged corporate yoga. After each session, I walked away feeling vibrant, energized, relaxed, and stress-free. It is not a feeling that any other sport could give you – it feels amazing!
                    </p>
                    <img src={femaleAvatar} alt='femaleAvatar'/>
                    <h5>Karishma Nageshwara</h5>
                    <p>HR Manager</p>
                </div>
                <div className='homepage-new-seventh-sec-main'>
                    <p>
                        Yoganta Corporate Yoga session was a very pleasant and relaxing break from our usual routine. The sessions were done in a pace and structure where you didn't need any special clothes or shoes. I feel well-rested to tackle the rest of my day.
                    </p>
                    <img src={femaleAvatar} alt='femaleAvatar'/>
                    <h5>Tulika Sudershan</h5>
                    <p>HR Manager</p>
                </div>      
            </Carousel>
            </section>
                
            <section className='corporate-get-started'>
                <div className='corporate-get-started-main'>
                    <div className='corporate-get-started-main-text'>
                        <h2>Get Started</h2>
                        <p>Speak directly to our Corporate Wellness Program Coordinator, <br/> Email : <a href="mailto:hello@yoganta.in">hello@yoganta.in</a> <br/> Call : <a href="tel:+919985955527">+91 99859 55527</a></p>
                    </div>
                </div>
            </section>

            <section className="corporate-gallery">
                <h2>Corporate Wellness Gallery</h2>
                <img src={yogantaCorporate1} alt="corporate yoga collage at IBS Hyderabad by Yoganta" />
                {readMore && extraContent}
                <button className="life-yoganta-btn" type="button" onClick={()=>{setReadMore(!readMore)}} data-toggle="collapse" data-target="#moreimages" aria-expanded="false" aria-controls="moreimages">
                <h3>{linkName}</h3>
                </button>
            </section>
            
            
            
            
            
            {/* <section className="life-yoganta">
                <h1>Corporate Diaries</h1>
                <img src={imagetwo} alt="cannot load image" /><br />
                <button class="btn btn-outline-secondary show-more-less" type="button" data-toggle="collapse" data-target="#moreimages" aria-expanded="false" aria-controls="moreimages">
                See more ↓
                </button>
                <div class="collapse" id="moreimages">
                <div class="card card-body">
                <img src={imageone} alt="cannot load image" />
                </div>
                </div>
            </section> */}

      <PunchLine message="Empowering  Individuals With Personal Health"/>

        </div>
    );
}

export default Corporate;