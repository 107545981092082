import React from 'react'
import './Pricing.css';

import {CgCheckO,CgRadioCheck} from 'react-icons/cg';


function Prices(props) {

        return (
        <div className={`payment-main-div${props.isActive?"-selected":""}`} onClick={() => props.handelClick(props.id)}>
            <div className="payment-main-month">{props.isActive?<CgCheckO/>:<CgRadioCheck/>}&emsp;&emsp;{props.duration} {props.id===4?"Week Trial":props.id===3?"Month":"Months"}</div>
            <div className="payment-main-money"><span>{props.original}</span> &emsp; ₹ <b>{props.acutal}</b></div>
        </div>
    )
}

export default Prices;

