import React from "react";
import "./Pricing.css";
import {Helmet} from "react-helmet";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import PunchLine from "../commonComps/PunchLine";
import PopupStatic from '../../components/pricing/Popup/PopupStatic';
import CountDownPage from '../countdown/CountDownPage';

function Pricing({punchmsg}) {
  return (
    <div>
      {/* Metadata */}
        <Helmet>
          <title>Plans & Pricing - Yoganta</title>
          <meta name="description" content="Book a Free Trial, Plans & Pricing, CHOOSE WHAT SUITS YOU BEST, For Pricing and Booking Call us Phone : +91-99859 55527. E-Mail: hello@yoganta.in" />
          <link rel="canonical" href="https://www.yoganta.in/pricing"/>
          <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
          <meta name="keywords" content="yoganta yoga plan & pricing, yoganta yoga package, live online yoga 1 on 1 classes, yoganta phone number, yoganta email id" />
        
        </Helmet>
      {/* Metadata */}
      <section className='pricing-head'>
        <div className='pricing-main'>
          <h1>Plans  & Pricing</h1>
          <p><strong>CHOOSE WHAT SUITS YOU BEST</strong></p>
          <p>Contact us and let us help you decide what practice fits your lifestyle best.</p>
        </div>
      </section>
      <section className=''>
                <div className=''>
                    <CountDownPage/>
                </div>
            </section>
      <section className='pricing-popup-static'>
      <div className='pricing-popup-static-main'>
        <PopupStatic/>
      </div>
      </section>
      <section className='pricing-sec-three'>
        <div className='pricing-sec-three-main'>
          <h3>Live 1 on 1 classes</h3>
          <p>Train one-on-one with our team of personal trainers!</p>
          <p className="one-on-one"><strong>Call</strong> <a href="tel:+919985955527"><span>+91 99859 55527</span></a> and tell us about your fitness goal!</p>
        </div>
      </section>

      <section className='pricing-get-started'>
        <div className='pricing-get-started-main'>
          <div className='pricing-get-started-main-text'>
            <p>To Know more details about Pricing and Booking, Call us</p>
          </div>
          <div className='pricing-get-started-main-contact'>
            <div><FaPhoneAlt /><a href="tel:+919985955527"><span>+91 99859 55527</span></a></div>
            <div><IoMdMail /><a href="mailto:hello@yoganta.in"><span>hello@yoganta.in</span></a></div>
          </div>
        </div>
      </section>
      <PunchLine message="START YOUR JOURNEY TO ACHIEVING INNER PEACE!" />
    </div>
  );
}

export default Pricing;
